/**
 * API to call Phileass services.
 */

/**
 * GET BusinessActivity list.
 *
 * @export
 * @param {*} apiUrl
 * @returns
 */
export function getBusinessActivities (apiUrl) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/businessactivities', {
      method: 'GET'
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * GET BusinessCategory list.
 *
 * @export
 * @param {*} apiUrl
 * @returns
 */
export function getBusinessCategories (apiUrl) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/businesscategories', {
      method: 'GET'
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * GET LegalFormCategory list.
 *
 * @export
 * @param {*} apiUrl
 * @returns
 */
export function getLegalFormCategories (apiUrl) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/legalformcategories', {
      method: 'GET'
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * GET InsuranceDomain list.
 *
 * @export
 * @param {*} apiUrl
 * @returns
 */
export function getInsuranceDomains (apiUrl) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/insurancedomains', {
      method: 'GET'
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * GET InsuranceProduct list.
 *
 * @export
 * @param {*} apiUrl
 * @returns
 */
export function getInsuranceProducts (apiUrl) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/insuranceproducts', {
      method: 'GET'
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * Store a new ContactRequest.
 * POST /contactrequests
 *
 * @export
 * @param {*} apiUrl
 * @param {*} contactRequest
 * @returns
 */
export function postContactRequest (apiUrl, contactRequest) {
  return new Promise((resolve, reject) => {
    // Call API
    window.fetch(apiUrl + '/contactrequests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contactRequest)
    }).then(function (response) {
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(function (err) {
      reject(err)
    })
  })
}

/**
 * Store a new QuoteRequest.
 *
 * @export
 * @param {*} apiUrl
 * @param {*} quoteRequest
 * @returns
 */
export function postQuoteRequest (apiUrl, quoteRequest) {
  return new Promise((resolve, reject) => {
    console.log('postQuoteRequest:\napiUrl:', apiUrl, '\nquoteRequest: ', JSON.stringify(quoteRequest))
    // Call API
    window.fetch(apiUrl + '/quoterequests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(quoteRequest)
    }).then(response => {
      // console.log('Received response: ', JSON.stringify(response))
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(err => {
      console.log('Error: ', err)
      reject(err)
    })
  })
}

export function putQuoteRequest (apiUrl, id, quoteRequest) {
  return new Promise((resolve, reject) => {
    console.log('putQuoteRequest:\napiUrl: ', apiUrl, '\nid: ', id, '\nquoteRequest: ', JSON.stringify(quoteRequest))
    // Call API
    window.fetch(apiUrl + '/quoterequests/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(quoteRequest)
    }).then(response => {
      // console.log('putQuoteRequest: Received response: ', response)
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(err => {
      console.log('Error: ', err)
      reject(err)
    })
  })
}

export function postQuoteRequestQuote (apiUrl, id) {
  return new Promise((resolve, reject) => {
    console.log('postQuoteRequestQuote:\napiUrl: ', apiUrl, '\nid: ', id)
    // Call API
    window.fetch(apiUrl + '/quoterequests/' + id + '/quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      // console.log('postQuoteRequestQuote: Received response: ', response)
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(err => {
      console.log('Error: ', err)
      reject(err)
    })
  })
}

export function postQuoteSubscribe (apiUrl, id, values) {
  return new Promise((resolve, reject) => {
    console.log('postQuoteSubscribe:\napiUrl: ', apiUrl, '\nid: ', id, '\nvalues: ', values)
    // Call API
    window.fetch(apiUrl + '/quotes/' + id + '/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }).then(response => {
      // console.log('postQuoteRequestQuote: Received response: ', response)
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(err => {
      console.log('Error: ', err)
      reject(err)
    })
  })
}

export function putSubscribeRequest (apiUrl, id, values) {
  return new Promise((resolve, reject) => {
    console.log('putSubscribeRequest:\napiUrl: ', apiUrl, '\nid: ', id, '\nvalues: ', values)
    // Call API
    window.fetch(apiUrl + '/subscriptionrequests/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }).then(response => {
      // console.log('postQuoteRequestQuote: Received response: ', response)
      // TODO: Check all response statuses
      if (response.status !== 200) {
        // TODO: parse error response
        // { statusCode, error, message }
        // reject(new SubmissionError({
        //   _error: 'Server error'
        // }))
        reject(new Error(response.statusText))
      }
      resolve(response.json())
    }).catch(err => {
      console.log('Error: ', err)
      reject(err)
    })
  })
}
