import React from 'react'

import { Link } from 'gatsby'
// import * as Yup from 'yup'
import _ from 'lodash'

import { reduxForm, formValueSelector, Field, FormSection, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'

import * as API from '../../../api/API'
import { startQuoteRequest, updateQuoteRequest, quoteQuoteRequest } from '../../../state/actions'
import { QUOTE_STATE_PENDING } from '../../../state/reducers'

import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'

import IcPrevious from '@material-ui/icons/ArrowBack'
import IcNext from '@material-ui/icons/ArrowForward'

import FTextField from '../../ui/FTextField'
import FButton from '../../ui/FButton'
import FSelectionControl from '../../ui/FSelectionControl'
import FMaskTextField from '../../ui/FMaskTextField'

import styles from './styles'
import CustomerServiceCard from '../../layout/CustomerServiceCard'
import FAddressFields from '../../ui/FAddressFields'
// import FDebugProps from '../../ui/FDebugProps'

function findInsuranceCategory (id, insuranceCategoriesData) {
  for (let i = 0; i < insuranceCategoriesData.length; i++) {
    const insCat = insuranceCategoriesData[i].insuranceCategories.find(element => element.id === id)
    if (insCat !== undefined) {
      return insCat
    }
  }
  return undefined
}

function validate (values, props) {
  // console.log('validate')

  let errors = {}

  const insuranceCategoriesData = props.formData
    ? props.formData.insuranceCategories.data
    : []
  const turnover = props.activityValues
    ? props.activityValues.turnover
    : ''

  let foundOne = false
  if (values && values.insuranceCategories && _.isObject(values.insuranceCategories)) {
    let errorsIns = {}
    Object.keys(values.insuranceCategories).map((valueKey) => {
      // console.log('validating entry ', valueKey)
      // InsuranceCategory.id = value
      const value = values.insuranceCategories[valueKey]
      let errorsValue = {}
      // console.log('selected ', value.selected)
      foundOne = foundOne || value.selected
      if (value.selected) {
        const insCat = findInsuranceCategory(valueKey, insuranceCategoriesData)
        // TODO: check required fields for InsuranceCategory
        if (insCat && insCat.requireBenefitLimit) {
          if (value.benefitLimit === '') {
            // console.log('benefitLimit empty ', value.benefitLimit)
            errorsValue.benefitLimit = 'Un plafond de garantie est requis.'
          }
          const benefitLimitsFiltered = filterBenefitLimits(insCat.benefitLimits, turnover)
          // console.log('turnover', turnover, 'value.benefitLimit', value.benefitLimit, 'benefitLimitsFiltered', benefitLimitsFiltered)
          if ((benefitLimitsFiltered === undefined) || (_.indexOf(benefitLimitsFiltered, Number(value.benefitLimit)) < 0)) {
            errorsValue.benefitLimit = 'Un plafond de garantie est requis.'
          }
        }
        if (insCat && insCat.requireLocation) {
          if (value.surface === '') {
            errorsValue.surface = 'Une surface est requise.'
          }
          if (value.occupantCategory === '') {
            errorsValue.occupantCategory = "Une catégorie d'occupant est requise."
          }
          // Address
          if (_.isNil(value.address) || _.isEmpty(value.address)) {
            errorsValue.address = {}
            errorsValue.address.addressLine1 = 'Une adresse est requise.'
          } else {
            const addressErrors = {}
            const address = value.address
            if (address.addressLine1 === '') {
              addressErrors.addressLine1 = 'Une adresse est requise.'
            }
            if (address.postalCode === '') {
              addressErrors.postalCode = 'Un code postal est requis.'
            }
            if (address.city === '') {
              addressErrors.city = 'Une ville est requise.'
            }
            if (!_.isEmpty(addressErrors)) {
              errorsValue.address = addressErrors
            }
          }
          if (value.equipmentValue === '') {
            errorsValue.equipmentValue = "Une valeur d'équipement est requise."
          }
          if (value.equipmentValueIT === '') {
            errorsValue.equipmentValueIT = "La valeur de la partie d'équipement informatique est requise."
          }
        }
        if (!_.isEmpty(errorsValue)) {
          errorsIns[valueKey] = errorsValue
        }
      }
    })
    if (!_.isEmpty(errorsIns)) {
      errors.insuranceCategories = errorsIns
    }
  }
  if (!foundOne) {
    errors['_error'] = 'Au moins une couverture est requise.'
  }
  // errors.comment = checkRequired('')(values.comment)

  return errors
}

function convertToEntity (values, props) {
  const entity = {}

  if (values) {
    const insuranceCategoriesData = (props.formData
      ? props.formData.insuranceCategories.data
      : [])

    if (values && values.insuranceCategories) {
      const entityInsCats = []
      Object.keys(values.insuranceCategories).forEach(key => {
        if (values.insuranceCategories[key].selected) {
          const insCat = findInsuranceCategory(key, insuranceCategoriesData)
          const entityInsCat = {}
          _.set(entityInsCat, 'insuranceCategory', key)
          if (insCat) {
            if (insCat.requireBenefitLimit) {
              _.set(entityInsCat, 'benefitLimit', _.get(values, `insuranceCategories.${key}.benefitLimit`))
            }
            if (insCat.requireLocation) {
              _.set(entityInsCat, 'surface', FMaskTextField.parseValue(_.get(values, `insuranceCategories.${key}.surface`, '')))
              _.set(entityInsCat, 'occupantCategory', _.get(values, `insuranceCategories.${key}.occupantCategory`))
              _.set(entityInsCat, 'address.addressLine1', _.get(values, `insuranceCategories.${key}.address.addressLine1`))
              _.set(entityInsCat, 'address.addressLine2', _.get(values, `insuranceCategories.${key}.address.addressLine2`))
              _.set(entityInsCat, 'address.postalCode', _.get(values, `insuranceCategories.${key}.address.postalCode`))
              _.set(entityInsCat, 'address.city', _.get(values, `insuranceCategories.${key}.address.city`))
              _.set(entityInsCat, 'address.equipmentValue', FMaskTextField.parseValue(_.get(values, `insuranceCategories.${key}.equipmentValue`, '')))
              _.set(entityInsCat, 'address.equipmentValueIT', FMaskTextField.parseValue(_.get(values, `insuranceCategories.${key}.equipmentValueIT`, '')))
            }
            if (insCat.requirePerson) {
              _.set(entityInsCat, 'firstname', _.get(values, `insuranceCategories.${key}.firstname`))
              _.set(entityInsCat, 'lastname', _.get(values, `insuranceCategories.${key}.lastname`))
              _.set(entityInsCat, 'birthdate', _.get(values, `insuranceCategories.${key}.birthdate`))
              _.set(entityInsCat, 'smoker', _.get(values, `insuranceCategories.${key}.smoker`))
              _.set(entityInsCat, 'sumInsured', FMaskTextField.parseValue(_.get(values, `insuranceCategories.${key}.sumInsured`, '')))
              _.set(entityInsCat, 'tpi', _.get(values, `insuranceCategories.${key}.tpi`))
            }
          }
          entityInsCats.push(entityInsCat)
        }
      })
      _.set(entity, 'insuranceCategories', entityInsCats)
    }

    _.set(entity, 'comment', _.get(values, 'comment'))

    // First step values
    _.set(entity, 'companyName', _.get(props, 'activityValues.companyName'))
    _.set(entity, 'mainBusinessActivity', _.get(props, 'activityValues.mainBusinessActivity.value'))
    _.set(entity, 'legalFormCategory', _.get(props, 'activityValues.legalFormCategory'))
    _.set(entity, 'turnover', FMaskTextField.parseValue(_.get(props, 'activityValues.turnover', '')))
    _.set(entity, 'postalCode', _.get(props, 'activityValues.postalCode'))
    _.set(entity, 'contactTitle', _.get(props, 'activityValues.contactTitle'))
    _.set(entity, 'contactFirstname', _.get(props, 'activityValues.contactFirstname'))
    _.set(entity, 'contactLastname', _.get(props, 'activityValues.contactLastname'))
    _.set(entity, 'contactRole', _.get(props, 'activityValues.contactRole'))
    _.set(entity, 'contactEmail', _.get(props, 'activityValues.contactEmail'))
    _.set(entity, 'contactPhone1', _.get(props, 'activityValues.contactPhone1'))
    _.set(entity, 'contactPhone2', _.get(props, 'activityValues.contactPhone2'))
    _.set(entity, 'contactOptIn', _.get(props, 'activityValues.contactOptIn'))
  }

  return entity
}

function filterBenefitLimits (benefitLimits, turnover) {
  if (benefitLimits === undefined || !_.isArray(benefitLimits)) {
    return undefined
  }

  let turnoverValue = turnover || 0
  if (turnover && _.isString(turnover) && turnover !== '') {
    turnoverValue = FMaskTextField.parseValue(turnover)
  }

  // Sort and filters
  return benefitLimits
    .sort((a, b) => (a - b))
    .filter((value, index) => (value >= turnoverValue))
}

function computeRecommendedLimit (benefitLimits, turnover) {
  const benefitLimitsFiltered = filterBenefitLimits(benefitLimits, turnover)
  if (benefitLimitsFiltered === undefined) {
    return undefined
  }
  return (benefitLimitsFiltered.length > 0 ? benefitLimitsFiltered[0] : undefined)
}

function renderBenefitLimits (benefitLimits, turnover) {
  const benefitLimitsFiltered = filterBenefitLimits(benefitLimits, turnover)
  if (benefitLimitsFiltered === undefined) {
    return null
  }

  const benefitLimitListItems = benefitLimitsFiltered.map((limit, index) => {
    if (index === 0) {
      return (
        <MenuItem key={limit} value={limit}>
          <Typography inline variant='body2' color='primary'>
            <strong>{Number(limit).toLocaleString()}&nbsp;€</strong>
            <Typography inline variant='caption' color='primary'>
              <i>&nbsp;Recommandation Phileass</i>
            </Typography>
          </Typography>
        </MenuItem>
      )
    } else {
      return (
        <MenuItem key={limit} value={limit}>
          <Typography inline variant='body2'>
            {Number(limit).toLocaleString()}&nbsp;€
          </Typography>
        </MenuItem>
      )
    }
  })
  return benefitLimitListItems
}

class InsuranceCategory extends React.Component {
  render () {
    const {
      insuranceCategory,
      // Theme
      classes,
      // Initialization data
      // formData,
      // Redux-Form
      values,
      activityValues
    } = this.props

    const benefitLimitListItems = renderBenefitLimits(insuranceCategory.benefitLimits, activityValues.turnover)

    const isSelected =
      values &&
      values.insuranceCategories &&
      values.insuranceCategories[insuranceCategory.id] &&
      values.insuranceCategories[insuranceCategory.id].selected

    return (
      <React.Fragment>
        <Grid container
          direction='row'
          justify='center'
          // alignItems='center'
          alignItems='stretch'
          alignContent='stretch'
          // spacing={24}
          className={classes.itemContainer}
        >
          <Grid item xs={12} sm={3}>
            <Field component={FSelectionControl}
              id={`quote_${insuranceCategory.id}_selected`}
              name='selected'
              label={insuranceCategory.title}
              className={classes.textField}
              controlType='switch'
            />
          </Grid>
          <Grid item xs={12} sm={9}
            className={classes.itemDescriptionContainer}
          >
            <Typography variant='body2' className={classes.itemDescriptionText}>
              {insuranceCategory.header}
            </Typography>
            {
              insuranceCategory.contentUrl &&
                <Button
                  className={classes.linkButton}
                  component={Link}
                  to={insuranceCategory.contentUrl}
                >
                  En savoir plus
                </Button>
            }
          </Grid>
          {
            isSelected &&
            insuranceCategory.requireBenefitLimit &&
            <Grid item xs={12}
              className={classes.itemSubFieldsContainer}
            >
              <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_benefitLimit`}
                name='benefitLimit'
                label='Plafond de garantie'
                placeholder='Sélectionnez le plafond de garantie'
                required
                alwaysDisplayError
                select
                className={classes.textField}
              >
                { benefitLimitListItems }
              </Field>
            </Grid>
          }
          {
            isSelected &&
            insuranceCategory.requireLocation &&
            <Grid item xs={12}
              className={classes.itemSubFieldsContainer}
            >
              <Field component={FMaskTextField}
                id={`quote_${insuranceCategory.id}_surface`}
                name='surface'
                label='Surface'
                placeholder='Saisissez la surface occupée'
                startAdornment={<InputAdornment position='start'>m<sup>2</sup></InputAdornment>}
                required
                // fullWidth={false}
                // className={classes.textFieldHalf}
                className={classes.textField}
              />
              {/* <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_surface`}
                name='surface'
                label='Surface'
                placeholder='Saisissez la surface de votre emplacement'
                required
                className={classes.textField}
              /> */}
              <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_occupantCategory`}
                name='occupantCategory'
                label="Qualité de l'occupant"
                placeholder="Sélectionnez votre qualité d'occupant"
                required
                select
                className={classes.textField}
              >
                <MenuItem key='LESSEE' value='LESSEE'>
                  <Typography inline variant='body2'>
                    Locataire
                  </Typography>
                </MenuItem>
                <MenuItem key='FREE_OCCUPANT' value='FREE_OCCUPANT'>
                  <Typography inline variant='body2'>
                    Occupant à titre gratuit
                  </Typography>
                </MenuItem>
                <MenuItem key='OWNER' value='OWNER'>
                  <Typography inline variant='body2'>
                    Propriétaire
                  </Typography>
                </MenuItem>
                <MenuItem key='SUB_LESSEE' value='SUB_LESSEE'>
                  <Typography inline variant='body2'>
                    Sous-locataire
                  </Typography>
                </MenuItem>
              </Field>
              <FAddressFields
                sectionName='address'
                fieldBaseId={`quote_${insuranceCategory.id}_address`}
              />
              {/* <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_address`}
                name='address'
                label='Adresse'
                placeholder="Saisissez l'adresse de votre emplacement"
                required
                className={classes.textField}
              /> */}
              {/* <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_equipmentValue`}
                name='equipmentValue'
                label='Valeur du mobilier et équipements'
                placeholder='Sélectionnez la valeur du mobilier et équipements'
                required
                className={classes.textField}
              /> */}
              <Field component={FMaskTextField}
                id={`quote_${insuranceCategory.id}_equipmentValue`}
                name='equipmentValue'
                label='Valeur du mobilier et équipements'
                placeholder='Saisissez la valeur du mobilier et équipements'
                startAdornment={<InputAdornment position='start'>EUR</InputAdornment>}
                required
                className={classes.textField}
              />
              <Field component={FMaskTextField}
                id={`quote_${insuranceCategory.id}_equipmentValueIT`}
                name='equipmentValueIT'
                label='Dont valeur des équipements informatiques'
                placeholder='Saisissez la valeur des équipements informatiques'
                startAdornment={<InputAdornment position='start'>EUR</InputAdornment>}
                required
                className={classes.textField}
              />
            </Grid>
          }
          {
            isSelected &&
            insuranceCategory.requirePerson &&
            <Grid item xs={12}
              className={classes.itemSubFieldsContainer}
            >
              <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_firstname`}
                name='firstname'
                label='Prénom'
                placeholder='Saisissez le prénom de la personne à assurer'
                // required
                className={classes.textField}
              />
              <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_lastname`}
                name='lastname'
                label='Nom'
                placeholder='Saisissez le nom de la personne à assurer'
                // required
                className={classes.textField}
              />
              <Field component={FTextField}
                id={`quote_${insuranceCategory.id}_birthdate`}
                type='date'
                InputLabelProps={{
                  shrink: true,
                  variant: 'outlined'
                }}
                name='birthdate'
                label='Date de naissance de la personne à assurer'
                // label='Date de naissance'
                // placeholder='Saisissez la date de naissance de la personne à assurer'
                // required
                className={classes.textField}
              />
              <Field component={FSelectionControl}
                id={`quote_${insuranceCategory.id}_smoker`}
                name='smoker'
                label='Fumeur'
                // label={
                //   <Typography variant='caption'>Fumeur</Typography>
                // }
                className={classes.switchField}
                controlType='switch'
                // labelPlacement='start'
              />
              <Field component={FMaskTextField}
                id={`quote_${insuranceCategory.id}_sumInsured`}
                name='sumInsured'
                label='Capital à assurer'
                placeholder='Saisissez le capital à assurer'
                helperText="Décès et PTIA (Perte Totale et Irréversible d'Autonomie)"
                startAdornment={<InputAdornment position='start'>EUR</InputAdornment>}
                className={classes.textField}
              />
              <Field component={FSelectionControl}
                id={`quote_${insuranceCategory.id}_tpi`}
                name='tpi'
                label='Couverture IPT (Invalidité Permanente Totale)'
                // label={
                //   <Typography variant='caption'>Couverture IPT (Invalidité Permanente Totale)</Typography>
                // }
                className={classes.switchField}
                controlType='switch'
                // labelPlacement='start'
              />
            </Grid>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

class InsuranceCategories extends React.Component {
  render () {
    const {
      // Theme
      classes,
      // Initialization data
      formData,
      // Redux-Form
      values,
      activityValues
    } = this.props

    const insuranceCategoriesData = (formData
      ? formData.insuranceCategories.data
      : [])

    return (
      <React.Fragment>
        {
          insuranceCategoriesData.map((insDomain, domIndex) => (
            <React.Fragment>
              <Paper className={classes.formSectionContainer}>
                <Typography
                  variant='h2'
                  className={classes.formSectionTitle}
                >
                  {insDomain.title}
                </Typography>
                <div className={classes.formFields}>
                  {
                    insDomain.insuranceCategories.map((insCategory, catIndex) => (
                      <FormSection name={insCategory.id}>
                        <InsuranceCategory
                          insuranceCategory={insCategory}
                          classes={classes}
                          formData={formData}
                          values={values}
                          activityValues={activityValues}
                        />
                      </FormSection>
                    ))
                  }
                </div>
              </Paper>
            </React.Fragment>
          ))
        }
      </React.Fragment>
    )
  }
}

class QuotePageCoversForm extends React.Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (values, dispatch) {
    // console.log('QuoteFormPageCovers submit')
    // Choose between create/update if entity present
    if (!_.isNil(this.props.quoteContext) &&
      this.props.quoteContext.state === QUOTE_STATE_PENDING &&
      !_.isNil(this.props.quoteContext.quoteRequest) &&
      this.props.quoteContext.quoteRequest.id !== '') {
      // Update quoteRequest
      return API.putQuoteRequest(this.props.apiUrl, this.props.quoteContext.quoteRequest.id, convertToEntity(values, this.props))
        .then(
          (quoteRequest) => {
            dispatch(updateQuoteRequest(quoteRequest))
            return Promise.resolve(quoteRequest)
          },
          (err) => {
            throw new SubmissionError({
              _error: err.statusText
            })
          }
        )
        .then(
          (quoteRequest) => {
            return API.postQuoteRequestQuote(this.props.apiUrl, quoteRequest.id)
              .then(
                (quote) => {
                  // console.log('Resolve with quote: ', quote)
                  dispatch(quoteQuoteRequest(quote))
                  return Promise.resolve(quote)
                },
                (err) => {
                  throw new SubmissionError({
                    _error: err.statusText
                  })
                }
              )
              .then(
                (quote) => {
                  return this.props.navigate(this.props.wizard.nextPath)
                }
              )
          }
        )
    }

    // TODO: Should not happen
    // Create new quoteRequest
    return API.postQuoteRequest(this.props.apiUrl, convertToEntity(values, this.props))
      .then(
        (quoteRequest) => {
          dispatch(startQuoteRequest(quoteRequest))
          return Promise.resolve(quoteRequest)
        },
        (err) => {
          throw new SubmissionError({
            _error: err.statusText
          })
        }
      )
      .then(
        (quoteRequest) => {
          return API.postQuoteRequestQuote(this.props.apiUrl, quoteRequest.id)
            .then(
              (quote) => {
                // console.log('Resolve with quote: ', quote)
                dispatch(quoteQuoteRequest(quote))
                return Promise.resolve(quote)
              },
              (err) => {
                throw new SubmissionError({
                  _error: err.statusText
                })
              }
            )
            .then(
              (quote) => {
                return this.props.navigate(this.props.wizard.nextPath)
              }
            )
        }
      )
  }

  render () {
    const {
      // Theme
      classes,
      // Initialization data
      formData,
      isLoading,
      // Router
      navigate,
      // Wizard
      wizard: {
        pageIndex,
        previousPath,
        nextPath
      },
      // Redux-Form
      values,
      error,
      handleSubmit,
      // reset,
      // submitFailed,
      // submitSucceeded,
      submitting,
      valid,
      // Redux connector
      activityValues
    } = this.props

    const isWaiting = (isLoading || submitting)

    return (
      <React.Fragment>
        <div id='top' className={classes.stepperContainer}>
          <Stepper activeStep={pageIndex}
            alternativeLabel
            // orientation='vertical'
          >
            <Step key='step0'>
              <StepLabel>Votre société et vous</StepLabel>
            </Step>
            <Step key='step1'>
              <StepLabel>Vos couvertures</StepLabel>
            </Step>
            <Step key='step2'>
              <StepLabel>Vos devis</StepLabel>
            </Step>
          </Stepper>
        </div>

        <Grid container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
          alignContent='stretch'
          spacing={40}
          className={classes.pageGrid}
        >
          <Grid item xs>

            {
              isWaiting &&
              <div className={classes.progressContainer}>
                <CircularProgress
                  variant='indeterminate'
                  size={100}
                  className={classes.progress}
                />
              </div>
            }
            {
              // Do not display if not submitted?
              // error &&
              //   <Typography>Submit error: {error}</Typography>
            }

            {/* <FDebugProps
              values={this.props.values}
              // {...this.props}
            /> */}

            <form onSubmit={handleSubmit(this.onSubmit)} className={classes.container}>

              <FormSection name='insuranceCategories'>
                <InsuranceCategories
                  classes={classes}
                  formData={formData}
                  values={values}
                  activityValues={activityValues}
                />
              </FormSection>

              <Paper className={classes.formSectionContainer}>
                <Typography
                  variant='h2'
                  className={classes.formSectionTitle}
                >
                  Des besoins spécifiques ?
                </Typography>
                <div className={classes.formFields}>
                  <Field component={FTextField}
                    id='quote_comment'
                    name='comment'
                    label='Commentaire'
                    placeholder='Saisissez vos commentaires ou décrivez vos besoins spécifiques'
                    multiline
                    rows='8'
                    className={classes.textAreaField}
                  />
                </div>
              </Paper>

              <div className={classes.buttonsContainer}>
                { previousPath &&
                <FButton type='button'
                  label={
                    <>
                      <IcPrevious className={classes.buttonIconPrevious} />
                      Votre société
                    </>
                  }
                  submittingLabel='Veuillez patienter...'
                  submitting={submitting}
                  variant='outlined' color='primary'
                  className={classes.button}
                  onClick={() => (navigate(previousPath))}
                />
                }
                <div className={classes.spaceGrow} />
                <FButton type='submit'
                  label={
                    <>
                      Obtenir mes devis
                      <IcNext className={classes.buttonIconNext} />
                    </>
                  }
                  submittingLabel='Veuillez patienter...'
                  valid={valid}
                  submitting={submitting}
                  variant='contained' color='primary'
                  className={classes.button}
                />
              </div>

            </form>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomerServiceCard
              text="Vous avez besoin d'aide ou tout simplement d'un renseignement&nbsp;?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  static computeInitialValues (insuranceCategoriesData, activityValues) {
    let values = {}
    values.insuranceCategories = {}

    if (insuranceCategoriesData) {
      const turnover = activityValues && activityValues.turnover !== ''
        ? activityValues.turnover
        : ''

      insuranceCategoriesData.map((insDomain, domIndex) => {
        insDomain.insuranceCategories.map((insCategory, catIndex) => {
          let insCatValues = {}
          insCatValues.selected = false
          if (insCategory.requireBenefitLimit) {
            // insCatValues.benefitLimit = ''
            const limit = computeRecommendedLimit(insCategory.benefitLimits, turnover)
            insCatValues.benefitLimit = limit ? limit.toString() : ''
          }
          if (insCategory.requireLocation) {
            insCatValues.surface = ''
            insCatValues.occupantCategory = ''
            insCatValues.address = {}
            insCatValues.address.addressLine1 = ''
            insCatValues.address.addressLine2 = ''
            insCatValues.address.postalCode = ''
            insCatValues.address.city = ''
            insCatValues.equipmentValue = ''
            insCatValues.equipmentValueIT = ''
          }
          values.insuranceCategories[insCategory.id] = insCatValues
        })
      })
    }

    // console.log('Computed initial values', values)
    return values
  }
}

QuotePageCoversForm.initialValues = {
  comment: ''
}

// Selector decorator
const formSelector = formValueSelector('quoteCovers')

function mapStateToProps (state) {
  // Extract latest quoteRequest & form values
  return {
    quoteContext: state.quote,
    values: {
      insuranceCategories: formSelector(state, 'insuranceCategories')
    }
  }
}

export default reduxForm({
  form: 'quoteCovers',
  validate: validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
  // updateUnregisteredFields: false
})(
  connect(mapStateToProps)(
    withStyles(styles)(QuotePageCoversForm)
  )
)
