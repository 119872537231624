import React from 'react'

import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

// import classNames from 'classnames'

const styles = (theme) => ({
  root: {
    padding: '0.5rem 1.5rem'
  }
})

class FButton extends React.Component {
  render () {
    const {
      type,
      onClick,
      label,
      disabledLabel,
      submittingLabel,
      disabled,
      valid,
      submitting,
      variant,
      color,
      classes,
      className
    } = this.props

    const _disabled = (disabled || (!valid) || submitting)
    const _disabledLabel = (disabledLabel || label)
    const _submittingLabel = (submittingLabel || label)

    return (
      <Button type={type} onClick={onClick}
        disabled={_disabled}
        variant={variant}
        color={color}
        className={className || classes.root}
      >
        { submitting
          ? _submittingLabel
          : _disabled
            ? _disabledLabel
            : label
        }
      </Button>
    )
  }
}

FButton.defaultProps = {
  valid: true,
  submitting: false,
  variant: 'contained',
  color: 'primary'
}

export default withStyles(styles)(FButton)
