import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'

const styles = theme => ({
  // TODO?
})

class FSelectionControl extends React.Component {
  render () {
    const {
      input: {
        name,
        value,
        // checked,
        onBlur,
        onChange,
        onFocus
      },
      id,
      label,
      controlType,
      color,
      fullWidth,
      className,
      classes,
      labelPlacement,
      ...compProps
    } = this.props

    let UIControl
    switch (controlType) {
      case 'switch':
        UIControl = Switch
        break
      default:
        UIControl = Checkbox
    }

    return (
      <FormControlLabel
        id={id}
        name={name}
        control={
          <UIControl
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            // checked={checked}
            checked={value}
            color={color}
            {...compProps}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        className={className}
      />
    )
  }
}

FSelectionControl.defaultProps = {
  color: 'primary',
  controlType: 'checkbox',
  labelPlacement: 'end'
}

export default withStyles(styles)(FSelectionControl)
