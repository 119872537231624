import React from 'react'

import _ from 'lodash'
import axios from 'axios'

import { reduxForm, formValueSelector, Field, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import IcPrevious from '@material-ui/icons/ArrowBack'
// import IcNext from '@material-ui/icons/ArrowForward'

import * as API from '../../../api/API'
import { updateSubscribeRequest } from '../../../state/actions'

import FTextField from '../../ui/FTextField'
import FRadioGroup from '../../ui/FRadioGroup'
import FButton from '../../ui/FButton'

import styles from './styles'
import CustomerServiceCard from '../../layout/CustomerServiceCard'

class QuotePageSubscriptionForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      companiesRegistrationsSearch: {},
      providerError: false
    }

    this.searchOnReturn = (event) => {
      if (event.key === 'Enter') {
        // Cancel the default action, if needed
        event.preventDefault()
        // Search
        this.searchCompaniesRegistrations(this.props.values.searchCompaniesRegistrations)
      }
    }

    this.searchCompaniesRegistrations = this.searchCompaniesRegistrations.bind(this)
    this.convertToEntity = this.convertToEntity.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  searchCompaniesRegistrations (companyName) {
    if (_.isNil(companyName) || _.isEmpty(companyName)) {
      this.setState({
        companiesRegistrationsSearch: {},
        providerError: false
      })
      return
    }
    axios.get(
      // Look up from companyName
      `https://entreprise.data.gouv.fr/api/sirene/v1/full_text/${encodeURIComponent(companyName)}`,
      {
        params: {
          // Max elements
          per_page: 10
        }
      }
    )
      .then((response) => {
        console.log(response)
        this.setState({
          companiesRegistrationsSearch: response.data,
          providerError: false
        })
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // No results
          this.setState({
            companiesRegistrationsSearch: {},
            providerError: false
          })
        } else {
          this.setState({
            companiesRegistrationsSearch: {},
            providerError: true
          })
          console.log('Error', error.message)
        }
      })
  }

  convertToEntity (values) {
    const entity = {}

    const providerSiren = _.get(values, 'providerCompanyRegistrationSiren')
    if (
      !_.isNil(providerSiren) &&
      !_.isEmpty(providerSiren) &&
      providerSiren !== 'OTHER'
    ) {
      // Get provider values
      const companiesRegistrations = _.get(this.state.companiesRegistrationsSearch, 'etablissement')
      const companyRegistration = _.find(
        companiesRegistrations,
        (value, index) => (_.get(value, 'siren', '') === providerSiren)
      )
      _.set(entity, 'subscription.quoteRequest.companyRegistration.siren', _.get(companyRegistration, 'siren'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.companyName', _.get(companyRegistration, 'nom_raison_sociale'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.siret', _.get(companyRegistration, 'siret'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.nic', _.get(companyRegistration, 'nic'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.legalForm', _.get(companyRegistration, 'nature_juridique_entreprise'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.legalFormLabel', _.get(companyRegistration, 'libelle_nature_juridique_entreprise'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.mainBusinessActivity', _.get(companyRegistration, 'activite_principale_entreprise'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.mainBusinessActivityLabel', _.get(companyRegistration, 'libelle_activite_principale_entreprise'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.address.addressLine1', _.get(companyRegistration, 'l4_normalisee'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.address.postalCode', _.get(companyRegistration, 'code_postal'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.address.city', _.get(companyRegistration, 'libelle_commune'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.verified', true)
    } else {
      // Get text fields values
      _.set(entity, 'subscription.quoteRequest.companyRegistration.siren', _.get(values, 'userCompanyRegistrationSiren'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.companyName', _.get(values, 'userCompanyRegistrationName'))
      _.set(entity, 'subscription.quoteRequest.companyRegistration.verified', false)
    }

    return entity
  }

  onSubmit (values, dispatch) {
    console.log('QuoteFormPageSubscriptiopn submit')

    const entity = this.convertToEntity(values)
    // console.log('Submiting entity', entity)

    return API.putSubscribeRequest(this.props.apiUrl, this.props.quoteContext.subscribeRequest.id, entity)
      .then(
        (subscribeRequest) => {
          // console.log('Resolve with subscribeRequest: ', subscribeRequest)
          dispatch(updateSubscribeRequest(subscribeRequest))
          return Promise.resolve(subscribeRequest)
        },
        (err) => {
          throw new SubmissionError({
            _error: err.statusText
          })
        }
      )
      .then(
        (subscribeRequest) => {
          return this.props.navigate(this.props.wizard.nextPath)
        }
      )
  }

  componentDidMount () {
    const {
      quoteContext
    } = this.props

    const quoteRequest = !_.isNil(quoteContext) && quoteContext.quoteRequest

    if (!_.isNil(quoteRequest)) {
      const companyName = quoteRequest.companyName
      // const postalCode = quoteRequest.postalCode
      this.searchCompaniesRegistrations(companyName)
    }
  }

  render () {
    const {
      // Theme
      classes,
      // Initialization data
      // formData,
      isLoading,
      // Router
      navigate,
      // Wizard
      wizard: {
        pageIndex,
        previousPath,
        nextPath
      },
      // Redux-Form
      error,
      handleSubmit,
      // reset,
      // submitFailed,
      // submitSucceeded,
      submitting,
      valid,
      values
    } = this.props

    const {
      companiesRegistrationsSearch,
      providerError
    } = this.state

    const isWaiting = (isLoading || submitting)

    return (
      <React.Fragment>
        <div id='top' className={classes.stepperContainer}>
          <Typography variant='h1'>
            Identification de votre entreprise
          </Typography>
        </div>

        <Grid container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
          alignContent='stretch'
          spacing={40}
          className={classes.pageGrid}
        >
          <Grid item xs>

            {
              isWaiting &&
                <div className={classes.progressContainer}>
                  <CircularProgress
                    variant='indeterminate'
                    size={100}
                    className={classes.progress}
                  />
                </div>
            }
            {
              error &&
                <Typography>Submit error: {error}</Typography>
            }

            <form onSubmit={handleSubmit(this.onSubmit)} className={classes.form}>

              <div className={classes.sectionContainer}>
                {/* <Typography variant='body2' className={classes.text}>
                    Toute l'équipe Phileass vous remercie pour votre confiance.
                  </Typography> */}
                <Typography variant='body2' className={classes.text}>
                  Afin de pouvoir compléter votre demande de souscription, merci de bien vouloir nous confirmer les informations d'identification de votre société.
                </Typography>
              </div>

              <Paper className={classes.formSectionContainer}>

                <div className={classes.sectionContainer}>
                  <Typography
                    variant='h2'
                    className={classes.sectionTitle}
                  >
                    Sélectionnez votre société
                  </Typography>

                  <div className={classes.formFields}>
                    <Field component={FRadioGroup}
                      id='quote_providerCompanyRegistrationSiren'
                      name='providerCompanyRegistrationSiren'
                      autoComplete='organization'
                      className={classes.radioField}
                    >
                      {
                        providerError &&
                          <Typography variant='body2' className={classes.messageText}>
                            * Service de recherche actuellement non fonctionnel *
                          </Typography>
                      }
                      {
                        !providerError &&
                        (
                          _.isNil(companiesRegistrationsSearch) ||
                          _.isNil(companiesRegistrationsSearch.etablissement) ||
                          _.isEmpty(companiesRegistrationsSearch.etablissement)
                        ) &&
                          <Typography variant='body2' className={classes.messageText}>
                            Aucune correspondance
                          </Typography>
                      }
                      {
                        companiesRegistrationsSearch && companiesRegistrationsSearch.etablissement &&
                        companiesRegistrationsSearch.etablissement.map((et) => (
                          <FormControlLabel
                            value={et.siren}
                            control={<Radio color='primary' />}
                            label={`${et.nom_raison_sociale} - ${et.siren} (${et.libelle_activite_principale})`}
                          />
                        ))
                      }
                      <FormControlLabel
                        value='OTHER'
                        control={<Radio color='primary' />}
                        // style={{
                        //   alignItems: 'flex-start',
                        //   paddingTop: '16px'
                        // }}
                        classes={{
                          root: classes.radioOther,
                          label: classes.radioOtherLabel
                        }}
                        label={
                          <div
                            // style={{
                            //   flex: '1 0 auto',
                            //   width: '100%',
                            //   padding: '12px 0px'
                            // }}
                          >
                            <Typography
                              variant='h3'
                              className={classes.sectionTitle}
                            >
                              Votre société n'apparaît pas dans la liste&nbsp;?
                            </Typography>
                            <div className={classes.formFields}>
                              <Field component={FTextField}
                                id='quote_userCompanyRegistrationName'
                                name='userCompanyRegistrationName'
                                autoComplete='organization'
                                label='Raison sociale de votre société'
                                placeholder='Saisissez la raison sociale de votre société'
                                className={classes.textField}
                                required={providerError}
                              />
                              <Field component={FTextField}
                                id='quote_userCompanyRegistrationSiren'
                                name='userCompanyRegistrationSiren'
                                label='Numéro SIREN de votre société (si disponible)'
                                placeholder='Saisissez le numéro SIREN de votre société'
                                className={classes.textField}
                              // required={providerError}
                              />
                            </div>
                          </div>
                        }
                      />
                    />
                    </Field>
                    {/* <Field component={FTextField}
                      id='quote_searchCompaniesRegistrations'
                      name='searchCompaniesRegistrations'
                      autoComplete='organization'
                      label='Raison sociale de votre société / numéro SIREN'
                      placeholder='Saisissez la raison sociale de votre société ou votre numéro SIREN'
                      className={classes.textField}
                      onKeyPress={this.searchOnReturn}
                      disabled={providerError}
                    />
                    <FButton type='button'
                      label='Rechercher'
                      // submittingLabel='Veuillez patienter...'
                      // submitting={submitting}
                      variant='outlined' color='primary'
                      className={classes.button}
                      onClick={() => (this.searchCompaniesRegistrations(values.searchCompaniesRegistrations))}
                      disabled={providerError}
                    /> */}
                  </div>

                </div>

              </Paper>

              <div className={classes.buttonsContainer}>
                {
                  previousPath &&
                    <FButton type='button'
                      label={
                        <>
                          <IcPrevious className={classes.buttonIconPrevious} />
                          Précédent
                        </>
                      }
                      submittingLabel='Veuillez patienter...'
                      submitting={submitting}
                      variant='outlined' color='primary'
                      className={classes.button}
                      onClick={() => (navigate(previousPath))}
                    />
                }
                <div className={classes.spaceGrow} />
                <FButton type='submit'
                  label='Confirmer votre demande de souscription'
                  submittingLabel='Veuillez patienter...'
                  valid={valid}
                  submitting={submitting}
                  variant='contained' color='primary'
                  className={classes.button}
                />
              </div>

            </form>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomerServiceCard
              text="Vous avez besoin d'aide ou tout simplement d'un renseignement&nbsp;?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  static computeInitialValues (activityValues) {
    let values = {}

    values.searchCompaniesRegistrations = activityValues.companyName
    values.userCompanyRegistrationName = activityValues.companyName

    return values
  }
}

QuotePageSubscriptionForm.initialValues = {
  providerCompanyRegistrationSiren: '',
  userCompanyRegistrationSiren: ''
}

// Selector decorator
const formSelector = formValueSelector('quoteSubscription')

function mapStateToProps (state) {
  // Extract latest quoteRequest
  return {
    quoteContext: state.quote,
    values: formSelector(state, 'searchCompaniesRegistrations', 'userCompanyRegistrationName')
  }
}

export default reduxForm({
  form: 'quoteSubscription',
  // validate: validateSchema(QuoteFormPageSubscription.validationSchema),
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(
  connect(mapStateToProps, null)(
    withStyles(styles)(QuotePageSubscriptionForm)
  )
)
