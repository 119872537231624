import React from 'react'

import _ from 'lodash'

import { withStyles, CardActionArea } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'

import { siteTheme } from './theme'

import ImgIcTel from '../../images/ic_tel_01.svg'

const styles = theme => ({
  card: {
    height: '100%',
    boxShadow: siteTheme.boxShadow1,
    backgroundColor: siteTheme.white,
    padding: '24px',
    paddingBottom: '0px' // 36 - 24 (last-item css)
  },
  cardContent: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'flex-start'
    padding: '0px'
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    color: siteTheme.color8,
    flex: '1 0 auto'
  },
  phoneContainer: {
    margin: '16px 0px',
    padding: '8px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgPhoneContainer: {
    flex: '0 0 auto',
    height: '50px'
  },
  imgPhone: {
    width: '50px',
    height: 'auto',
    margin: '0px 20px 0px 0px'
  },
  textPhoneContainer: {
    flex: '0 0 auto'
    // width: '100%'
    // textAlign: 'center'
  },
  textPhone: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color: siteTheme.color8
    // textAlign: 'center'
  },
  openContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0px'
  },
  openSlotContainer: {
    flex: '1 0 70%'
    // overflowX: 'hidden'
  },
  textOpenSlot: {
    fontSize: '16px',
    lineHeight: '18px',
    color: siteTheme.color8
    // '&::after': {
    //   fontSize: '16px',
    //   lineHeight: '18px',
    //   color: siteTheme.color8,
    //   content: '"............................................................"',
    //   // float: 'right',
    //   width: 0,
    //   whiteSpace: 'nowrap'
    // }
  },
  textOpenTime: {
    fontSize: '16px',
    lineHeight: '18px',
    color: siteTheme.color8,
    textAlign: 'center',
    flex: '0 1 auto'
  }
})

class CustomerServiceCard extends React.Component {
  render () {
    const {
      text,
      classes
    } = this.props

    const textVal = text || 'Vous pouvez également contacter notre service client :'
    const textComp = _.isString(textVal)
      ? (
        <Typography variant='body2' className={classes.textVal}>
          { textVal }
        </Typography>
      )
      : textVal

    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          { textComp }
          <CardActionArea component='a' href='tel:+33972638706'>
            <div className={classes.phoneContainer}>
              <div className={classes.imgPhoneContainer}>
                <img src={ImgIcTel} alt='Icon Téléphone' className={classes.imgPhone} />
              </div>
              <div className={classes.textPhoneContainer}>
                <Typography component='p' className={classes.textPhone}>
                09&nbsp;72&nbsp;63&nbsp;87&nbsp;06
                </Typography>
              </div>
            </div>
          </CardActionArea>
          <div>
            {/* <Typography component='p' className={classes.text}>
              Horaires&nbsp;:
            </Typography> */}
            <div className={classes.openContainer}>
              <div className={classes.openSlotContainer}>
                <Typography component='p' className={classes.textOpenSlot}>
                  Lundi - Vendredi
                </Typography>
              </div>
              <Typography component='p' className={classes.textOpenTime}>
                9h00<br />18h00
              </Typography>
            </div>
            <div className={classes.openContainer}>
              <div className={classes.openSlotContainer}>
                <Typography component='p' className={classes.textOpenSlot}>
                  Samedi
                </Typography>
              </div>
              <Typography component='p' className={classes.textOpenTime}>
                9h00<br />14h00
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(CustomerServiceCard)
