import React from 'react'

import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    margin: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`
  }
})

/*
props:
input:
  checked : boolean
  name : String
  onBlur(eventOrValue) : Function
  onChange(eventOrValue) : Function
  onDragStart(event) : Function
  onDrop(event) : Function
  onFocus(event) : Function
  value: any
meta:
  active : boolean
  autofilled : boolean
  asyncValidating : boolean
  dirty : boolean
  dispatch : Function
  error : String [optional]
  form : String
  initial : any
  invalid : boolean
  pristine : boolean
  submitting : boolean
  submitFailed : boolean
  touched : boolean
  valid : boolean
  visited: boolean
  warning : String [optional]

  input: {
    checked,
    name,
    onBlur,
    onChange,
    onDragStart,
    onDrop,
    onFocus,
    value
  },
  meta: {
    active,
    autofilled,
    asyncValidating,
    dirty,
    dispatch,
    error,
    form,
    initial,
    invalid,
    pristine,
    submitting,
    submitFailed,
    touched,
    valid,
    visited,
    warning
  }
*/

class FTextField extends React.Component {
  render () {
    const {
      input,
      meta: {
        touched,
        invalid,
        error
      },
      id,
      type,
      variant,
      fullWidth,
      helperText,
      startAdornment,
      alwaysDisplayError,
      classes,
      className,
      ...compProps
    } = this.props

    const helperTextError = (alwaysDisplayError
      ? error
      : touched && error)
    let helperTextContent
    if (helperText) {
      helperTextContent = (
        <React.Fragment>
          {helperTextError && <React.Fragment>{helperTextError}<br /></React.Fragment>}
          {helperText}
        </React.Fragment>
      )
    } else {
      helperTextContent = helperTextError
    }

    return (
      <TextField
        id={id}
        type={type}
        error={alwaysDisplayError
          ? invalid
          : touched && invalid
        }
        helperText={helperTextContent}
        InputProps={{
          startAdornment: startAdornment
        }}
        {...input}
        variant={variant}
        fullWidth={fullWidth}
        className={className || classes.root}
        {...compProps}
      />
    )
  }
}

FTextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true
}

export default withStyles(styles)(FTextField)
