import React from 'react'

import _ from 'lodash'

import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import * as API from '../../../api/API'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './styles'
import QuotePageCoversForm from './QuotePageCoversForm'

class QuotePageCovers extends React.Component {
  constructor (props) {
    super(props)

    // State definition
    this.state = {
      isFormInitialized: false,
      isLoading: false,
      formData: {
        insuranceCategories: {
          isLoading: false,
          isInitialized: false,
          isSuccess: false,
          error: '',
          data: []
        }
      },
      initialValues: QuotePageCoversForm.initialValues
    }

    // Bind class methods
    this.loadInsuranceCategories = this.loadInsuranceCategories.bind(this)
    this.loadFormData = this.loadFormData.bind(this)
  }

  loadInsuranceCategories () {
    if (!this.state.formData.insuranceCategories.isInitialized) {
      // Loading indicator
      this.setState(state => ({
        formData: _.merge({}, state.formData, {
          insuranceCategories: {
            isLoading: true
          }
        })
      }))
      // Make API call
      API.getInsuranceDomains(this.props.apiUrl).then(
        (insCategories) => {
          // console.log('InsuranceCategories loaded')
          // TODO: filter and group options
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              insuranceCategories: {
                isLoading: false,
                isInitialized: true,
                isSuccess: true,
                error: '',
                data: insCategories
              }
            })
          }))
          return insCategories
        },
        (error) => {
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              insuranceCategories: {
                isLoading: false,
                isInitialized: false,
                isSuccess: false,
                error: error,
                data: []
              }
            })
          }))
        }
      ).then(
        (insCategories) => {
          // console.log('Updating state wiht initial values')
          this.setState(state => ({
            initialValues: _.merge({}, state.initialValues,
              QuotePageCoversForm.computeInitialValues(insCategories, this.props.activityValues)
            )
          }))
        }
      ).then(
        () => {
          // console.log('Updating state isLoading')
          this.setState(state => ({
            isFormInitialized: state.formData.insuranceCategories.isInitialized,
            isLoading: state.formData.insuranceCategories.isLoading
          }))
        }
      )
    }
  }

  loadFormData () {
    if (this.props.apiUrl === undefined) {
      // Error
      return
    }
    if (this.state.isLoading) {
      // Already loading data
      return
    }
    this.setState({
      isLoading: true
    })

    this.loadInsuranceCategories()
  }

  componentDidMount () {
    this.loadFormData()
  }

  render () {
    const {
      // Router
      navigate,
      // Wizard
      wizard,
      // Redux connector
      activityValues,
      // Std
      classes,
      ...customProps
    } = this.props

    const {
      isFormInitialized,
      isLoading,
      formData,
      initialValues
    } = this.state

    // Do not mount component until form structure/fields are loaded
    if (!isFormInitialized) {
      // console.log('Not initialized, state initialValues', initialValues)
      return (
        <div className={classes.progressContainer}>
          <CircularProgress
            variant='indeterminate'
            size={100}
            className={classes.progress}
          />
        </div>
      )
    }
    // console.log('Initialized, state initialValues', initialValues)
    return (
      <QuotePageCoversForm
        navigate={navigate}
        wizard={wizard}
        formData={formData}
        initialValues={initialValues}
        isLoading={isLoading}
        activityValues={activityValues}
        {...customProps}
      />
    )
  }
}

QuotePageCovers.pageLabel = 'Vos couvertures'
QuotePageCovers.pagePath = 'vos-couvertures'

// Selector decorator
const formSelector = formValueSelector('quoteActivity')

function mapStateToProps (state) {
  // Extract latest quoteRequest & form values
  return {
    activityValues: formSelector(state,
      'companyName',
      'mainBusinessActivity',
      'legalFormCategory',
      'turnover',
      'postalCode',
      'contactTitle',
      'contactFirstname',
      'contactLastname',
      'contactRole',
      'contactEmail',
      'contactPhone1',
      'contactPhone2',
      'contactOptIn'
    )
  }
}

export default connect(mapStateToProps, null)(
  withStyles(styles)(QuotePageCovers)
)
