import React from 'react'

import Select from 'react-select'

import { withStyles } from '@material-ui/core/styles'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import CancelIcon from '@material-ui/icons/Cancel'

import classNames from 'classnames'
import { isNil, isEmpty } from 'lodash'

const styles = theme => ({
  input: {
    display: 'flex',
    // padding: 0
    padding: '9.5px 14px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    fontStyle: 'italic'
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
})

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <TextField
      id={props.selectProps.id}
      name={props.selectProps.name}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function GroupHeading (props) {
  return (
    <ListSubheader
      component='div'
      // TODO: define style (background, shading, ...)
      style={{
        background: 'white'
      }}
      {...props.innerProps}
    >
      {props.children}
    </ListSubheader>
  )
}

function Placeholder (props) {
  if (props.isFocused) {
    return (
      <Typography
        color='textSecondary'
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    )
  }
  return null
}

function SingleValue (props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue (props) {
  return (
    <Chip
      // color='primary'
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu (props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Placeholder,
  Menu,
  Option,
  GroupHeading,
  NoOptionsMessage,
  SingleValue,
  MultiValue,
  ValueContainer
}

/**
 * Extract label from option.
 * Option: Object, by default { label: String, value: String }
 *
 * @param {*} option
 * @returns
 */
function defaultGetOptionLabel (option) {
  if (option === undefined) {
    return undefined
  }
  return option.label
}

/**
 * Extract value from option.
 * Option: Object, by default { label: String, value: String }
 *
 * @param {*} option
 * @returns
 */
function defaultGetOptionValue (option) {
  if (option === undefined) {
    return undefined
  }
  return option.value
}

// function singleChangeHandler (onChangeHandler, getOptionValue) {
//   // type ActionTypes = | 'clear' | 'create-option' | 'deselect-option' | 'pop-value' | 'remove-value' | 'select-option' | 'set-value'
//   return function handleSingleChange (option, { action }) {
//     onChangeHandler(option ? getOptionValue(option) : '')
//   }
// }

// function multiChangeHandler (onChangeHandler, getOptionValue) {
//   // type ActionTypes = | 'clear' | 'create-option' | 'deselect-option' | 'pop-value' | 'remove-value' | 'select-option' | 'set-value'
//   return function handleMultiHandler (options, { action }) {
//     onChangeHandler(options.map(option => getOptionValue(option)))
//   }
// }

// function adaptValueToSelect (value, options, multi, getOptionValue) {
//   if (multi && ((typeof value) === 'string')) {
//     return []
//   }

//   const filteredOptions = options.filter(option => {
//     return multi
//       ? value.indexOf(getOptionValue(option)) !== -1
//       : getOptionValue(option) === value
//   })

//   return multi ? filteredOptions : filteredOptions[0]
// }

/**
 * Select form component with autocomplete suggestions.
 *
 * @class FAutocompleteSelect
 * @extends {React.Component}
 */
class FAutocompleteSelect extends React.Component {
  render () {
    const {
      input: {
        name,
        onBlur,
        onChange,
        onFocus,
        value
      },
      meta: {
        touched,
        invalid,
        error
      },
      id,
      label,
      placeholder,
      // options,
      // multi,
      // getOptionLabel,
      // getOptionValue,
      noOptionsMessage,
      noOptionsText,
      required,
      helperText,
      // classes,
      theme,
      // className,
      variant,
      color,
      ...compProps
    } = this.props

    // console.log('Autocomplete Render: value = ' + JSON.stringify(input.value))
    // const selectValue = adaptValueToSelect(value, options, multi, getOptionValue)

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }
    const noOptMsgFn = (noOptionsMessage || (() => noOptionsText))
    let inputLabelProps = {}
    if (!isNil(value) && !isEmpty(value)) {
      inputLabelProps.shrink = true
    }
    const helperTextError = (touched && error)
    let helperTextContent
    if (helperText) {
      helperTextContent = (
        <React.Fragment>
          {helperTextError && <React.Fragment>{helperTextError}<br /></React.Fragment>}
          {helperText}
        </React.Fragment>
      )
    } else {
      helperTextContent = helperTextError
    }

    return (
      <Select
        id={id}
        name={name}
        value={value}
        // value={selectValue}
        onChange={onChange}
        // onChange={
        //   multi
        //     ? multiChangeHandler(onChange, getOptionValue)
        //     : singleChangeHandler(onChange, getOptionValue)
        // }
        onBlur={(e) => onBlur(value)}
        // Bug on mobile (https://github.com/JedWatson/react-select/issues/2692)
        blurInputOnSelect={false}
        onFocus={onFocus}
        // options={options}
        // multi={multi}
        // getOptionLabel={getOptionLabel}
        // getOptionValue={getOptionValue}
        styles={selectStyles}
        components={components}
        noOptionsMessage={noOptMsgFn}
        isClearable
        textFieldProps={{
          label: label,
          placeholder: placeholder,
          required: required,
          error: (touched && invalid),
          helperText: helperTextContent,
          variant: variant,
          color: color,
          InputLabelProps: inputLabelProps
        }}
        // FormHelperTextProps={{
        //   //
        // }}
        {...compProps}
      />
    )
  }
}

FAutocompleteSelect.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  noOptionsText: 'Aucun résultat',
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue
}

export default withStyles(styles, { withTheme: true })(FAutocompleteSelect)
