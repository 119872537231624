import React from 'react'

import RadioGroup from '@material-ui/core/RadioGroup'
// import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

/**
 * Radio group form component.
 *
 * @class FRadioGroup
 * @extends {React.Component}
 */
class FRadioGroup extends React.Component {
  /**
   * React render method.
   *
   * @returns
   * @memberof FRadioGroup
   */
  render () {
    const {
      input,
      meta: {
        touched,
        invalid,
        error
      },
      id,
      label,
      className,
      required,
      children,
      ...compProps
    } = this.props

    return (
      <FormControl component='fieldset'
        className={className}
        required={required}
        error={touched && invalid}
      >
        { label && <FormLabel component='legend'>{label}</FormLabel> }
        <RadioGroup
          id={id}
          {...input}
          {...compProps}
        >
          {children}
        </RadioGroup>
        { touched && invalid &&
          <FormHelperText error>{error}</FormHelperText>
        }
      </FormControl>
    )
  }
}

export default FRadioGroup
