
import { siteTheme } from '../../layout/theme'

const formStyles = theme => ({
  stepperContainer: {
    padding: `${theme.spacing.unit * 3}px 0px`,
    width: `100%`
  },
  spaceGrow: {
    flexGrow: 1
  },

  text: {
    padding: '0px 0px 8px 0px'
  },
  messageText: {
    padding: '0px 0px 8px 0px',
    fontSize: '14px',
    fontStyle: 'italic'
  },
  sectionContainer: {
    // padding: '0px 0px 24px 0px'
    margin: '0px 0px 24px 0px'
  },
  sectionTitle: {
    padding: '0px 0px 16px 0px'
  },
  sectionDivider: {
    margin: '24px 0px 24px 0px'
  },

  form: {
    // display: 'block'
  },
  progressContainer: {
    position: 'fixed',
    top: '50vh',
    right: '50vw',
    minWidth: '100px',
    minHeight: '100px',
    transform: 'translate(-50%, -50%)'
  },
  progress: {
    // width: '100%'
  },
  formSectionContainer: {
    // display: 'block',
    // ...theme.mixins.gutters()
    boxShadow: siteTheme.boxShadow1,
    backgroundColor: siteTheme.white,
    padding: '24px 24px 32px 24px',
    marginBottom: '32px'
  },
  formSectionTitle: {
    padding: '0px 0px 16px 0px'
  },
  formFields: {
    // display: 'flex',
    // flexWrap: 'wrap'
    // paddingRight: '20px'
  },
  // formButtons: {
  //   display: 'flex',
  //   flexWrap: 'wrap'
  // },
  textField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`
  },
  textFieldHalf: {
    margin: `${theme.spacing.unit * 1.5}px 0px`,
    maxWidth: '50%'
  },
  textAreaField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`
    // width: '100%'
  },
  selectField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`,
    width: '100%'
    // flexGrow: 1
  },
  radioField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`,
    padding: `0px ${theme.spacing.unit * 2}px`,
    width: '100%'
  },
  switchField: {
    margin: `0px 0px 0px ${theme.spacing.unit * 2}px`
  },
  captchaField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`
  },

  itemContainer: {
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  itemDescriptionContainer: {
    paddingLeft: `${theme.spacing.unit * 2}px`
  },
  itemDescriptionText: {
    textAlign: 'justify',
    padding: `${theme.spacing.unit}px 0px`
  },
  itemSubFieldsContainer: {
    // paddingLeft: '46px',
    paddingBottom: '24px'
  },
  linkButton: {
    fontSize: '12px',
    // fontWeight: 'bold',
    // fontWeight: 'normal',
    lineHeight: '14px',
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    // padding: 0,
    // textTransform: 'uppercase'
  },

  productOfferCategory: {
    margin: `${theme.spacing.unit * 3}px 0px ${theme.spacing.unit * 2}px 0px`
  },
  productOffersContainer: {
    padding: `${theme.spacing.unit * 2}px 0px`
  },
  subInformationText: {
    fontSize: '14px',
    fontStyle: 'italic',
    margin: `${theme.spacing.unit * 1.5}px 0px`
  },
  unsatisfiedCategoryList: {
    padding: '16px 0px'
  },
  unsatisfiedCategoryListItem: {
    padding: '4px 0px'
  },
  unsatisfiedCategoryText: {
    fontWeight: 'bold'
  },

  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    margin: theme.spacing.unit * 2,
    textTransform: 'none',
    // boxShadow: siteTheme.boxShadow2
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  buttonIconPrevious: {
    marginRight: theme.spacing.unit
  },
  buttonIconNext: {
    marginLeft: theme.spacing.unit
  },
  successContainer: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    // backgroundColor: fade(theme.palette.primary.main, 0.2),
    border: `solid 1px ${theme.palette.primary.dark}`,
    borderRadius: theme.spacing.unit,
    textAlign: 'center'
  },
  errorContainer: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.error.main,
    // backgroundColor: fade(theme.palette.error.main, 0.2),
    border: `solid 1px ${theme.palette.error.dark}`,
    borderRadius: theme.spacing.unit,
    textAlign: 'center'
  },

  radioOther: {
    alignItems: 'flex-start',
    paddingTop: '16px'
  },
  radioOtherLabel: {
    flex: '1 1 auto',
    padding: '12px 0px'
  },

  confirmationHeader: {
    textAlign: 'center',
    margin: '24px 0px'
  },
  confirmationContainer: {
    padding: `${theme.spacing.unit * 3}px`,
    // padding: '24px 16px',
    textAlign: 'center'
  },
  confirmationIcDone: {
    fontSize: '64px'
  },
  confirmationTitle: {
    margin: '36px 0px'
  },
  confirmationText: {
    margin: '24px 0px'
  },
  confirmationButtonContainer: {
    margin: '36px 0px',
    textAlign: 'center'
  },
  confirmationButton: {
    // width: '100%',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    boxShadow: siteTheme.boxShadow4
  }
})

export default formStyles
