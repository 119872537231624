import React from 'react'

import { withStyles, MuiThemeProvider } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import SEO from '../SEO'
import QuoteHeader from './QuoteHeader'
import Footer from '../Footer'

import { siteTheme, muiTheme } from '../theme'

const styles = theme => ({
  // layout: {
  //   width: 'auto',
  //   padding: '0px',
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
  //     width: 1100,
  //     marginLeft: 'auto',
  //     marginRight: 'auto'
  //   }
  // },
  main: {
    padding: '0px',
    background: siteTheme.white
  }
})

function QuoteLayout (props) {
  const {
    classes,
    title,
    description,
    image,
    pathname,
    activeSection
  } = props

  return (
    <React.Fragment>
      <MuiThemeProvider theme={muiTheme}>
        <SEO title={title} description={description} image={image} pathname={pathname} />
        <Helmet>
          {/* <link href='https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700,700i' rel='stylesheet' /> */}
          <link href='https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i' rel='stylesheet' />
        </Helmet>
        <div>
          <QuoteHeader activeSection={activeSection} />
          <main className={classes.main}>
            {props.children}
          </main>
          <Footer />
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export default withStyles(styles)(QuoteLayout)
