import React from 'react'

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
// import { conformToMask } from 'text-mask'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  }
})

// function customInput (mask) {
//   return (props) => {
//     const {
//       inputRef,
//       ...other
//     } = props

//     return (
//       <MaskedInput
//         {...other}
//         ref={ref => {
//           inputRef(ref ? ref.inputElement : null)
//         }}
//         mask={mask}
//       />
//     )
//   }
// }
function CustomInput (props) {
  const {
    inputRef,
    ...other
  } = props

  const mask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: false
  })

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
    />
  )
}

class FMaskTextField extends React.Component {
  // constructor (props) {
  //   super(props)

  //   this.mask = createNumberMask({
  //     prefix: '',
  //     suffix: '',
  //     includeThousandsSeparator: true,
  //     thousandsSeparatorSymbol: ' ',
  //     allowDecimal: false,
  //     decimalSymbol: ',',
  //     decimalLimit: 2,
  //     allowNegative: false
  //   })
  // }

  render () {
    const {
      input,
      meta: {
        touched,
        invalid,
        error
      },
      id,
      variant,
      fullWidth,
      helperText,
      startAdornment,
      classes,
      className,
      ...compProps
    } = this.props

    // console.log('MaskTextField: render with value=' + value)
    // const valueStr = (value instanceof Number ? conformToMask(value.toString(), this.mask(value.toString())) : '')
    // const valueStr = (!isNaN(Number(value)) ? conformToMask(value.toString(), this.mask(value.toString())).conformedValue : '')
    // const valueStr = value.toString()
    // var numberFormat = new Intl.NumberFormat('fr-FR')
    // const valueStr = (value !== '' ? numberFormat.format(value).toString() : '')
    // console.log('MaskTextField: valueStr=' + valueStr)

    const helperTextError = (touched && error)
    let helperTextContent
    if (helperText) {
      helperTextContent = (
        <React.Fragment>
          {helperTextError && <React.Fragment>{helperTextError}<br /></React.Fragment>}
          {helperText}
        </React.Fragment>
      )
    } else {
      helperTextContent = helperTextError
    }

    return (
      <TextField
        id={id}
        {...input}
        error={touched && invalid}
        helperText={helperTextContent}
        InputProps={{
          startAdornment: startAdornment,
          // inputComponent: customInput(this.mask)
          inputComponent: CustomInput
        }}
        variant={variant}
        fullWidth={fullWidth}
        className={className || classes.textField}
        {...compProps}
      />
    )
  }

  static parseValue (valueStr) {
    //   console.log('conformToValue: valueStr=' + valueStr)
    const sanitizedValue = valueStr.replace(/\D/g, '')
    //   console.log('conformToValue: sanitizedValue=' + sanitizedValue)
    const value = Number(sanitizedValue)
    //   console.log('conformToValue: value=' + value)

    return (isNaN(value) ? '' : value)
  }
}

FMaskTextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true
}

export default withStyles(styles)(FMaskTextField)
