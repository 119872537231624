import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import QuotePageProductsForm from './QuotePageProductsForm'

class QuotePageProducts extends React.Component {
  constructor (props) {
    super(props)

    // State definition
    this.state = {
      // isFormInitialized: false,
      isFormInitialized: true,
      isLoading: false,
      initialValues: QuotePageProductsForm.initialValues
    }
  }

  render () {
    const {
      // Router
      navigate,
      // Wizard
      wizard,
      ...customProps
    } = this.props

    const {
      isLoading,
      initialValues
    } = this.state

    return (
      <QuotePageProductsForm
        navigate={navigate}
        wizard={wizard}
        initialValues={initialValues}
        isLoading={isLoading}
        {...customProps}
      />
    )
  }
}

QuotePageProducts.pageLabel = 'Vos devis personnalisés'
QuotePageProducts.pagePath = 'vos-devis'

export default withStyles(styles)(QuotePageProducts)
