import React from 'react'

import _ from 'lodash'
import * as Yup from 'yup'

import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'

import * as API from '../../../api/API'
import { startQuoteRequest, updateQuoteRequest } from '../../../state/actions'
import { QUOTE_STATE_PENDING } from '../../../state/reducers'
import { validateSchema } from '../formUtils'

import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import IcPrevious from '@material-ui/icons/ArrowBack'
import IcNext from '@material-ui/icons/ArrowForward'

import FAutocompleteSelect from '../../ui/FAutocompleteSelect'
// import FBooleanRadioGroup from '../../ui/FBooleanRadioGroup'
import FRadioGroup from '../../ui/FRadioGroup'
import FTextField from '../../ui/FTextField'
import FButton from '../../ui/FButton'
import FSelectionControl from '../../ui/FSelectionControl'
import FMaskTextField from '../../ui/FMaskTextField'
// import FNumberTextField from '../../ui/FNumberTextField'

import styles from './styles'
import CustomerServiceCard from '../../layout/CustomerServiceCard'

function convertToEntity (values) {
  const entity = {}

  _.set(entity, 'companyName', _.get(values, 'companyName'))
  _.set(entity, 'mainBusinessActivity', _.get(values, 'mainBusinessActivity.value'))
  _.set(entity, 'legalFormCategory', _.get(values, 'legalFormCategory'))
  _.set(entity, 'turnover', FMaskTextField.parseValue(_.get(values, 'turnover', '')))
  _.set(entity, 'postalCode', _.get(values, 'postalCode'))
  _.set(entity, 'contactTitle', _.get(values, 'contactTitle'))
  _.set(entity, 'contactFirstname', _.get(values, 'contactFirstname'))
  _.set(entity, 'contactLastname', _.get(values, 'contactLastname'))
  _.set(entity, 'contactRole', _.get(values, 'contactRole'))
  _.set(entity, 'contactEmail', _.get(values, 'contactEmail'))
  _.set(entity, 'contactPhone1', _.get(values, 'contactPhone1'))
  _.set(entity, 'contactPhone2', _.get(values, 'contactPhone2'))
  _.set(entity, 'contactOptIn', _.get(values, 'contactOptIn'))

  return entity
}

/**
 * TODO
 *
 * @class QuoteFormPageActivity
 * @extends {React.Component}
 */
class QuotePageActivityForm extends React.Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  /**
   * TODO
   *
   * @param {*} values
   * @param {*} dispatch
   * @returns
   * @memberof QuoteFormPageActivity
   */
  onSubmit (values, dispatch) {
    // console.log('Activity: submitting')
    // Choose between create/update if entity present
    if (!_.isNil(this.props.quoteContext) &&
      this.props.quoteContext.state === QUOTE_STATE_PENDING &&
      !_.isNil(this.props.quoteContext.quoteRequest) &&
      this.props.quoteContext.quoteRequest.id !== '') {
      // Update quoteRequest
      return API.putQuoteRequest(this.props.apiUrl, this.props.quoteContext.quoteRequest.id, convertToEntity(values))
        .then(
          (quoteRequest) => {
            dispatch(updateQuoteRequest(quoteRequest))
            return Promise.resolve(quoteRequest)
          },
          (err) => {
            throw new SubmissionError({
              _error: err.statusText
            })
          }
        )
        .then(
          (quoteRequest) => {
            return this.props.navigate(this.props.wizard.nextPath)
          }
        )
    }

    // Else: Create new quoteRequest
    return API.postQuoteRequest(this.props.apiUrl, convertToEntity(values))
      .then(
        (quoteRequest) => {
          dispatch(startQuoteRequest(quoteRequest))
          return Promise.resolve(quoteRequest)
        },
        (err) => {
          throw new SubmissionError({
            _error: err.statusText
          })
        }
      )
      .then(
        (quoteRequest) => {
          return this.props.navigate(this.props.wizard.nextPath)
        }
      )
  }

  /**
   * TODO
   *
   * @returns
   * @memberof QuoteFormPageActivity
   */
  render () {
    const {
      // Theme
      classes,
      // Initialization data
      formData,
      isLoading,
      // Router
      navigate,
      // Wizard
      wizard: {
        pageIndex,
        previousPath,
        nextPath
      },
      // Redux-Form
      error,
      handleSubmit,
      // reset,
      // submitFailed,
      // submitSucceeded,
      submitting,
      valid
    } = this.props

    const businessActivitiesData = (formData && formData.businessActivities
      ? formData.businessActivities.data
      : [])
    const legalFormsData = (formData && formData.legalForms
      ? formData.legalForms.data
      : [])

    const isWaiting = (isLoading || submitting)

    return (
      <React.Fragment>
        <div id='top' className={classes.stepperContainer}>
          <Stepper activeStep={pageIndex}
            alternativeLabel
            // orientation='vertical'
          >
            <Step key='step0'>
              <StepLabel>Votre société et vous</StepLabel>
            </Step>
            <Step key='step1'>
              <StepLabel>Vos couvertures</StepLabel>
            </Step>
            <Step key='step2'>
              <StepLabel>Vos devis</StepLabel>
            </Step>
          </Stepper>
        </div>

        <Grid container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
          alignContent='stretch'
          spacing={40}
          className={classes.pageGrid}
        >
          <Grid item xs>

            {
              isWaiting &&
              <div className={classes.progressContainer}>
                <CircularProgress
                  variant='indeterminate'
                  size={100}
                  className={classes.progress}
                />
              </div>
            }
            { error &&
            <Typography>Submit error: {error}</Typography>
            }

            <form onSubmit={handleSubmit(this.onSubmit)} className={classes.form}>

              <Paper className={classes.formSectionContainer}>
                <Typography
                  variant='h2'
                  className={classes.formSectionTitle}
                >
              Votre société
                </Typography>
                <div className={classes.formFields}>
                  <Field component={FTextField}
                    id='quote_companyName'
                    name='companyName'
                    autoComplete='organization'
                    label='Nom de la société'
                    placeholder='Saisissez le nom de la société'
                    required
                    // fullWidth={false}
                    className={classes.textField}
                  />
                  <Field component={FAutocompleteSelect}
                    id='quote_mainBusinessActivity'
                    name='mainBusinessActivity'
                    label='Activité'
                    placeholder='Saisissez votre activité'
                    required
                    options={businessActivitiesData}
                    className={classes.selectField}
                  />
                  {/* <Field component={FBooleanRadioGroup}
              id='quote_hasOtherActivities' name='hasOtherActivities'
              label="Exerce-t-elle d'autres activités ?"
              trueOptionLabel='Oui'
              falseOptionLabel='Non'
              required
              className={classes.radio}
              format={FBooleanRadioGroup.format}
              parse={FBooleanRadioGroup.parse}
            /> */}
                  <Field component={FMaskTextField}
                    id='quote_turnover'
                    name='turnover'
                    label="Chiffre d'affaire"
                    placeholder="Entrez le chiffre d'affaire de votre société"
                    helperText="Le chiffre d'affaires doit correspondre au dernier exercice clos ou au prévisionnel pour les créations."
                    startAdornment={<InputAdornment position='start'>EUR</InputAdornment>}
                    required
                    className={classes.textField}
                  />
                  {/* <Field component={FNumberTextField}
              id='quote_turnover' name='turnover'
              label="Chiffre d'affaire"
              placeholder="Entrez le chiffre d'affaire de votre société"
              helperText="Le chiffre d'affaires doit correspondre au dernier exercice clos ou au prévisionnel pour les créations."
              startAdornment={<InputAdornment position='start'>EUR</InputAdornment>}
              required
              className={classes.textField}
            /> */}
                  <Field component={FTextField}
                    id='quote_legalFormCategory'
                    name='legalFormCategory'
                    label='Type de société'
                    placeholder='Entrez le type de votre société'
                    required
                    select
                    className={classes.textField}
                  >
                    { legalFormsData.map((value, index) => (
                      <MenuItem key={value.id} value={value.id}>
                        <Typography inline variant='body2'>
                          {value.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Field>
                  <Field component={FTextField}
                    id='quote_postalCode'
                    name='postalCode'
                    autoComplete='postal-code'
                    label='Code Postal'
                    placeholder='Entrez votre code postal'
                    required
                    className={classes.textField}
                  />
                </div>
              </Paper>

              <Paper className={classes.formSectionContainer}>
                <Typography
                  variant='h2'
                  className={classes.formSectionTitle}
                >
              Contact
                </Typography>
                <div className={classes.formFields}>
                  <Field component={FRadioGroup}
                    id='quote_contactTitle'
                    name='contactTitle'
                    autoComplete='honorific-prefix'
                    required
                    row
                    className={classes.radioField}
                  >
                    <FormControlLabel value='MR' control={<Radio color='primary' />} label='Monsieur' />
                    <FormControlLabel value='MME' control={<Radio color='primary' />} label='Madame' />
                  </Field>
                  <Field component={FTextField}
                    id='quote_contactLastname'
                    name='contactLastname'
                    autoComplete='family-name'
                    label='Nom'
                    placeholder='Saisissez votre nom'
                    required
                    className={classes.textField}
                  />
                  <Field component={FTextField}
                    id='quote_contactFirstname'
                    name='contactFirstname'
                    autoComplete='given-name'
                    label='Prénom'
                    placeholder='Saisissez votre prénom'
                    required
                    className={classes.textField}
                  />
                  <Field component={FTextField}
                    id='quote_contactRole'
                    name='contactRole'
                    autoComplete='organization-title'
                    label='Fonction'
                    placeholder='Saisissez votre fonction'
                    className={classes.textField}
                  />
                  <Field component={FTextField}
                    id='quote_contactEmail'
                    name='contactEmail'
                    autoComplete='email'
                    label='Email'
                    placeholder='Saisissez votre adresse email'
                    required
                    className={classes.textField}
                  />
                  <Field component={FTextField}
                    id='quote_contactPhone1'
                    name='contactPhone1'
                    autoComplete='tel'
                    label='Téléphone'
                    placeholder='Saisissez votre numéro de téléphone'
                    required
                    className={classes.textField}
                  />
                  <Field component={FTextField}
                    id='quote_contactPhone2'
                    name='contactPhone2'
                    autoComplete='tel'
                    label='Téléphone complémentaire'
                    placeholder='Saisissez votre numéro de téléphone complémentaire (optionnel)'
                    className={classes.textField}
                  />
                  <Field component={FSelectionControl}
                    id='quote_contactOptIn'
                    name='contactOptIn'
                    label="Je déclare avoir pris connaissance de la notice d'information relative au traitement de mes données personnelles."
                    className={classes.textField}
                    controlType='switch'
                  />
                </div>
              </Paper>

              <div className={classes.buttonsContainer}>
                { previousPath &&
                <FButton type='button'
                  label={
                  <>
                    <IcPrevious className={classes.buttonIconPrevious} />
                    Précédent
                  </>
                  }
                  submittingLabel='Veuillez patienter...'
                  submitting={submitting}
                  variant='outlined' color='primary'
                  className={classes.button}
                  onClick={() => (navigate(previousPath))}
                />
                }
                <div className={classes.spaceGrow} />
                <FButton type='submit'
                  label={
                <>
                  Vos couvertures
                  <IcNext className={classes.buttonIconNext} />
                </>
                  }
                  submittingLabel='Veuillez patienter...'
                  valid={valid}
                  submitting={submitting}
                  variant='contained' color='primary'
                  className={classes.button}
                />
              </div>

            </form>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomerServiceCard
              text="Vous avez besoin d'aide ou tout simplement d'un renseignement&nbsp;?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

QuotePageActivityForm.validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Veuillez saisir le nom de votre société.'),
  mainBusinessActivity: Yup.object().nullable(true)
    .test('object-notEmpty', 'Veuillez choisir une activité.', (value) => !(_.isNil(value) || _.isEmpty(value))),
  legalFormCategory: Yup.string()
    .required('Veuillez choisir la forme de votre société.'),
  turnover: Yup
    .number()
    .typeError("Veuillez saisir votre chiffre d'affaire.")
    .min(0, "Le chiffre d'affaire doit être supérieur ou égal à 0")
    .max(25000000, "Le chiffre d'affaire ne peut dépasser 25 000 000 €"),
  postalCode: Yup.string()
    .required('Veuillez saisir un code postal'),
  contactTitle: Yup.string()
    .required('Veuillez choisir votre titre'),
  contactLastname: Yup.string()
    .required('Votre nom est requis'),
  contactFirstname: Yup.string()
    .required('Votre prénom est requis'),
  contactEmail: Yup.string()
    .required('Votre adresse email est requise')
    .email('Adresse email invalide'),
  contactPhone1: Yup.string()
    .required('Votre numéro de téléphone est requis'),
  // contactPhone2: Yup.string()
  //   .required('Votre numéro de téléphone est requis'),
  contactOptIn: Yup.boolean()
    .required()
    .oneOf([true], 'Vous devez accepter les conditions')
})

QuotePageActivityForm.initialValues = {
  companyName: '',
  mainBusinessActivity: '',
  legalFormCategory: '',
  turnover: '',
  postalCode: '',
  contactTitle: 'MR',
  contactFirstname: '',
  contactLastname: '',
  contactRole: '',
  contactEmail: '',
  contactPhone1: '',
  contactPhone2: '',
  contactOptIn: false
}

function mapStateToProps (state) {
  return {
    quoteContext: state.quote
  }
}

export default reduxForm({
  form: 'quoteActivity',
  validate: validateSchema(QuotePageActivityForm.validationSchema),
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(
  connect(mapStateToProps, null)(
    withStyles(styles)(QuotePageActivityForm)
  )
)
