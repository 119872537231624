import React from 'react'

import { Link } from 'gatsby'

import _ from 'lodash'

import * as API from '../../../api/API'

import { Field, FormSection, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
// import { validateSchema } from '../formUtils'

import ReactMarkdown from 'markdown-to-jsx'

import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IcLabelImportant from '@material-ui/icons/LabelImportant'

import Button from '@material-ui/core/Button'

import IcPrevious from '@material-ui/icons/ArrowBack'
import IcNext from '@material-ui/icons/ArrowForward'
import IcSelected from '@material-ui/icons/CheckCircle'

import FButton from '../../ui/FButton'

import styles from './styles'
import CustomerServiceCard from '../../layout/CustomerServiceCard'
import { subscribeQuote } from '../../../state/actions'
import ProductOfferCard from './ProductOfferCard'

const mdOptions = {
  overrides: {
    h1: { component: props => <Typography gutterBottom variant='h2' {...props} /> },
    h2: { component: props => <Typography gutterBottom variant='h3' {...props} /> },
    h3: { component: props => <Typography gutterBottom variant='subtitle1' {...props} /> },
    h4: { component: props => <Typography gutterBottom variant='caption' paragraph {...props} /> },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component='span' {...props} />
        </li>
      ))
    }
  }
}

function convertToEntity (values, props) {
  /*
  quoteOffers: {
    '5c59c5259da0ce4ff6b5c818': {
      productOffer: '5c8fd9e0a4466c932fcb789f'
    },
    '5c8b98713de7d56fdccd6976': {
      productOffer: '5c915f6edad719a154cd4c2a'
    }
  }
  */
  const quote = _.get(props, 'quoteContext.quote')
  // const quoteOffers = _.get(quote, 'quoteOffers')

  const entity = {}

  if (values) {
    const subscription = {}

    // Selected offers
    const subscriptionOffers = []
    if (values.quoteOffers) {
      _.forEach(values.quoteOffers, (quoteOffer, insCatId) => {
        // Find associated productOffer
        const prodOfferId = _.get(quoteOffer, 'productOffer')
        const dataQuoteOffer = _.find(quote.quoteOffers, (value, index) => (_.get(value, 'insuranceCategory.id', '') === insCatId))
        const dataProductOffer = dataQuoteOffer && _.find(dataQuoteOffer.productOffers, (value, index) => (_.get(value, 'insuranceProduct.id', '') === prodOfferId))

        if (dataProductOffer) {
          const subOffer = {}

          _.set(subOffer, 'insuranceCategory', insCatId)
          _.set(subOffer, 'insuranceCategoryName', _.get(dataQuoteOffer, 'insuranceCategory.name'))
          _.set(subOffer, 'productOffer.insuranceProduct', _.get(quoteOffer, 'productOffer'))
          _.set(subOffer, 'productOffer.insuranceProductName', _.get(dataProductOffer, 'insuranceProduct.name'))
          _.set(subOffer, 'productOffer.price', _.get(dataProductOffer, 'price'))
          _.set(subOffer, 'productOffer.currency', _.get(dataProductOffer, 'currency'))
          _.set(subOffer, 'productOffer.benefitLimit', _.get(dataProductOffer, 'benefitLimit'))
          // _.set(subOffer, 'productOffer.deductibleAmount', _.get(quoteOffer, 'quoteOffers[??].productOffers[??].deductibleAmount'))

          subscriptionOffers.push(subOffer)
        }
      })
    }
    _.set(subscription, 'subscriptionOffers', subscriptionOffers)

    // Add unsatisfied insurance categories
    const unsatisfiedInsuranceCategories = quote.quoteOffers
      .filter(quoteOffer => _.isNil(quoteOffer.productOffers) || _.isEmpty(quoteOffer.productOffers))
      .map(quoteOffer => {
        const val = {}
        _.set(val, 'insuranceCategory', _.get(quoteOffer, 'insuranceCategory.id'))
        _.set(val, 'insuranceCategoryName', _.get(quoteOffer, 'insuranceCategory.name'))
        return val
      })
    _.set(subscription, 'unsatisfiedInsuranceCategories', unsatisfiedInsuranceCategories)

    // TODO: copy quote request fields
    const quoteRequest = {}

    _.set(quoteRequest, 'companyName', _.get(quote, 'quoteRequest.companyName'))
    _.set(quoteRequest, 'mainBusinessActivity', _.get(quote, 'quoteRequest.mainBusinessActivity'))
    _.set(quoteRequest, 'turnover', _.get(quote, 'quoteRequest.turnover'))
    _.set(quoteRequest, 'postalCode', _.get(quote, 'quoteRequest.postalCode'))
    _.set(quoteRequest, 'contact.title', _.get(quote, 'quoteRequest.contactTitle'))
    _.set(quoteRequest, 'contact.firstname', _.get(quote, 'quoteRequest.contactFirstname'))
    _.set(quoteRequest, 'contact.lastname', _.get(quote, 'quoteRequest.contactLastname'))
    _.set(quoteRequest, 'contact.role', _.get(quote, 'quoteRequest.contactRole'))
    _.set(quoteRequest, 'contact.email', _.get(quote, 'quoteRequest.contactEmail'))
    _.set(quoteRequest, 'contact.phone1', _.get(quote, 'quoteRequest.contactPhone1'))
    _.set(quoteRequest, 'contact.phone2', _.get(quote, 'quoteRequest.contactPhone2'))
    _.set(quoteRequest, 'contact.optIn', _.get(quote, 'quoteRequest.contactOptIn'))
    _.set(quoteRequest, 'comment', _.get(quote, 'quoteRequest.comment'))

    _.set(subscription, 'quoteRequest', quoteRequest)

    _.set(entity, 'subscription', subscription)
  }

  return entity
}

function renderProduct (productOffer, quoteOffer, props) {
  return (
    <Grid item
      xs={12} sm={12} md={6}
      justify='center'
    >
      <ProductOfferCard productOffer={productOffer} {...props} />
    </Grid>
  )
}

class QuoteOfferField extends React.Component {
  render () {
    const {
      input: {
        name,
        value,
        onBlur,
        onChange,
        onFocus
      },
      id,
      className,
      classes,
      quoteOffer,
      ...compProps
    } = this.props

    return (
      <React.Fragment>
        <Typography variant='h2' className={classes.productOfferCategory}>
          {quoteOffer.insuranceCategory.title}
        </Typography>
        <Grid container
          direction='row'
          justify='center'
          // alignItems='center'
          alignItems='stretch'
          alignContent='stretch'
          spacing={24}
          className={classes.productOffersContainer}
        >
          {
            quoteOffer.productOffers.map(productOffer => renderProduct(productOffer, quoteOffer, this.props))
          }
        </Grid>
      </React.Fragment>
    )
  }
}

function filterInsuranceProducts (quoteOffers, props) {
  return quoteOffers
    .filter(quoteOffer => !_.isNil(quoteOffer.productOffers) && !_.isEmpty(quoteOffer.productOffers))
}

function renderInsuranceProducts (quoteOffers, props) {
  const {
    classes
  } = props

  const insuranceProducts = filterInsuranceProducts(quoteOffers)
  if (_.isEmpty(insuranceProducts)) {
    return null
  }

  const productsList = insuranceProducts.map(quoteOffer => {
    return (
      <FormSection name={quoteOffer.insuranceCategory.id}>
        <Field component={QuoteOfferField}
          id={`quoteOffers_${quoteOffer.insuranceCategory.id}_productOffer`}
          name='productOffer'
          // className={classes.textField}
          quoteOffer={quoteOffer}
          classes={classes}
        />
      </FormSection>
    )
  })

  return (
    <React.Fragment>
      {productsList}
      <Typography variant='body2' className={classes.subInformationText}>
        <strong>*</strong>&nbsp;: Sous réserve de respect des critères d'éligibilité et de confirmation des tarifs par les équipes Phileass.
      </Typography>
    </React.Fragment>
  )
}

function renderUnsatisfiedCategories (quoteOffers, props) {
  const {
    classes
  } = props

  const unsatisfiedCategories = quoteOffers
    .filter(quoteOffer => _.isNil(quoteOffer.productOffers) || _.isEmpty(quoteOffer.productOffers))
  if (_.isEmpty(unsatisfiedCategories)) {
    return null
  }

  return (
    <>
      <Typography variant='body2'>
        Nous avons bien pris en compte vos besoins et revenons vers vous dans les plus brefs délais.
      </Typography>

      <List className={classes.unsatisfiedCategoryList}>
        {
          unsatisfiedCategories.map(quoteOffer => (
            <ListItem
              alignItems='flex-start'
              className={classes.unsatisfiedCategoryListItem}
            >
              <ListItemIcon>
                <IcLabelImportant color='primary' />
              </ListItemIcon>
              <ListItemText>
                {/* <Typography variant='body1' inline className={classes.unsatisfiedCategoryText}> */}
                {quoteOffer.insuranceCategory.title}
                {/* </Typography> */}
              </ListItemText>
            </ListItem>
          ))
        }
      </List>
    </>
  )
}

class QuotePageProductsForm extends React.Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (values, dispatch) {
    console.log('QuoteFormPageProducts submit')

    const entity = convertToEntity(values, this.props)
    // console.log('Submiting entity', entity)

    return API.postQuoteSubscribe(this.props.apiUrl, this.props.quoteContext.quote.id, entity)
      .then(
        (subscribeRequest) => {
          // console.log('Resolve with subscribeRequest: ', subscribeRequest)
          dispatch(subscribeQuote(subscribeRequest))
          return Promise.resolve(subscribeRequest)
        },
        (err) => {
          throw new SubmissionError({
            _error: err.statusText
          })
        }
      )
      .then(
        (subscribeRequest) => {
          return this.props.navigate(this.props.wizard.nextPath)
        }
      )
  }

  render () {
    const {
      // Theme
      classes,
      // Initialization data
      // formData,
      isLoading,
      // Router
      navigate,
      // Wizard
      wizard: {
        pageIndex,
        previousPath,
        nextPath
      },
      // Redux-Form
      error,
      handleSubmit,
      // reset,
      // submitFailed,
      // submitSucceeded,
      submitting,
      valid,
      quoteContext
    } = this.props

    const isWaiting = (isLoading || submitting)
    // const insuranceProductsData = (formData
    //   ? formData.insuranceProducts.data
    //   : [])

    const quote = !_.isNil(quoteContext) && quoteContext.quote

    return (
      <React.Fragment>
        <div id='top' className={classes.stepperContainer}>
          <Stepper activeStep={pageIndex}
            alternativeLabel
            // orientation='vertical'
          >
            <Step key='step0'>
              <StepLabel>Votre société et vous</StepLabel>
            </Step>
            <Step key='step1'>
              <StepLabel>Vos couvertures</StepLabel>
            </Step>
            <Step key='step2'>
              <StepLabel>Vos devis</StepLabel>
            </Step>
          </Stepper>
        </div>

        <Grid container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
          alignContent='stretch'
          spacing={40}
          className={classes.pageGrid}
        >
          <Grid item xs>

            {
              isWaiting &&
              <div className={classes.progressContainer}>
                <CircularProgress
                  variant='indeterminate'
                  size={100}
                  className={classes.progress}
                />
              </div>
            }
            { error &&
            <Typography>Submit error: {error}</Typography>
            }

            <form onSubmit={handleSubmit(this.onSubmit)} className={classes.container}>
              <FormSection name='quoteOffers'>

                {/* <Typography variant='h3'>Votre sélection</Typography>
          <div className={classes.formFields}>
            // TODO
          </div> */}

                <Typography
                  variant='h1'
                  className={classes.formSectionTitle}
                >
                Vos devis
                </Typography>
                <div className={classes.formFields}>
                  {
                    quote && !_.isNil(quote.quoteOffers) &&
                    renderInsuranceProducts(quote.quoteOffers, this.props)
                  }
                </div>

                <Typography
                  variant='h2'
                  className={classes.productOfferCategory}
                >
                Autres besoins
                </Typography>
                <div className={classes.formFields}>
                  {
                    quote && !_.isNil(quote.quoteOffers) &&
                    renderUnsatisfiedCategories(quote.quoteOffers, this.props)
                  }
                </div>

                <div className={classes.buttonsContainer}>
                  { previousPath &&
                  <FButton type='button'
                    label={
                    <>
                      <IcPrevious className={classes.buttonIconPrevious} />
                      Vos couvertures
                    </>
                    }
                    submittingLabel='Veuillez patienter...'
                    submitting={submitting}
                    variant='outlined' color='primary'
                    className={classes.button}
                    onClick={() => navigate(previousPath)}
                  />
                  }
                  <div className={classes.spaceGrow} />
                  <FButton type='submit'
                    label={
                    <>
                      Valider mes choix
                      <IcNext className={classes.buttonIconNext} />
                    </>
                    }
                    submittingLabel='Veuillez patienter...'
                    valid={valid}
                    submitting={submitting}
                    variant='contained' color='primary'
                    className={classes.button}
                  />
                </div>

              </FormSection>
            </form>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomerServiceCard
              text="Vous avez besoin d'aide ou tout simplement d'un renseignement&nbsp;?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

// QuoteFormPageProducts.validationSchema = Yup.object().shape({
// })
QuotePageProductsForm.initialValues = {
}

function mapStateToProps (state) {
  // Extract latest quoteRequest
  return {
    quoteContext: state.quote
  }
}

export default reduxForm({
  form: 'quoteProducts',
  // validate: validateSchema(QuoteFormPageProducts.validationSchema),
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(
  connect(mapStateToProps, null)(
    withStyles(styles)(QuotePageProductsForm)
  )
)
