import _ from 'lodash'

export function validateSchema (validationSchema) {
  return function (values) {
    // console.log('Validation: ' + JSON.stringify(values))

    // Clean up values
    // let validateData = {}
    // for (let k in values) {
    //   if (values.hasOwnProperty(k)) {
    //     const key = String(k)
    //     validateData[key] = values[key] !== '' ? values[key] : undefined
    //   }
    // }

    // Run Yup validation
    // Yup validation options
    // {
    //   strict: boolean = false;
    //   abortEarly: boolean = true;
    //   stripUnknown: boolean = false;
    //   recursive: boolean = true;
    //   context?: object;
    // }
    try {
      // validationSchema.validateSync(validateData, {
      validationSchema.validateSync(values, {
        abortEarly: false
      })
      return {}
    } catch (yupError) {
      // Transform Yup ValidationError

      // console.log('YupError: ' + JSON.stringify(yupError))

      let errors = {}
      if (yupError.inner) {
        if (yupError.inner.length === 0) {
          // yupError.path === 'this' ?
          _.set(errors, yupError.path, yupError.message)
          return errors
        }
        for (let err of yupError.inner) {
          if (!_.has(errors, err.path)) {
            _.set(errors, err.path, err.message)
          }
        }
      }

      // console.log('Transformed error: ' + JSON.stringify(errors))

      return errors
    }
  }
}

export function checkRequired (value, message) {
  const msg = message || 'Le chanmp est requis.'
  return (
    _.isNil(value) ||
      _.isNaN(value) ||
      (_.isString(value) && (value === '')) ||
      ((_.isObject(value) || _.isArray(value)) && _.isEmpty(value))
      ? msg
      : undefined
  )
}

export function validateRequired (message) {
  return function (value, allValues, props, name) {
    return checkRequired(value, message)
  }
}
