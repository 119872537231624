import React from 'react'

import { Link } from 'gatsby'
import _ from 'lodash'

import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import IcDone from '@material-ui/icons/Done'

import FButton from '../../ui/FButton'

import styles from './styles'

class QuotePageConfirmation extends React.Component {
  render () {
    const {
      // Router
      navigate,
      // Wizard
      wizard: {
        // pageIndex,
        // previousPath,
        nextPath
      },
      // Redux
      quoteContext,
      classes
      // ...customProps
    } = this.props

    const emailAddress = quoteContext && _.get(quoteContext, 'subscribeRequest.subscription.quoteRequest.contact.email')

    return (
      <React.Fragment>
        <div id='top' className={classes.stepperContainer}>
          <Typography variant='h1' className={classes.confirmationHeader}>
            Merci pour votre demande de souscription
          </Typography>
        </div>

        <Paper className={classes.confirmationContainer}>
          <IcDone className={classes.confirmationIcDone} color='primary' />
          <Typography variant='h2' className={classes.confirmationTitle}>
                Toute l'équipe Phileass vous remercie pour votre demande de souscription.
          </Typography>
          <Typography variant='body2' className={classes.confirmationText}>
                Un récapitulatif de votre demande vous a été adressé par mail à l'adresse suivante&nbsp;:<br />
            <strong>{emailAddress}</strong>
          </Typography>
          <Typography variant='body2' className={classes.confirmationText}>
                Un conseiller prendra contact avec vous rapidement pour la finalisation de votre souscription.
          </Typography>
          <div className={classes.confirmationButtonContainer}>
            {/* <FButton type='button'
              label="Retour à l'accueil"
              // color='primary'
              // variant='contained'
              className={classes.successButton}
              onClick={() => (navigate(nextPath))}
            /> */}
            <Button type='button'
              component={Link}
              to='/'
              color='primary'
              variant='contained'
              className={classes.confirmationButton}
            >
              Retour à l'accueil
            </Button>
          </div>
        </Paper>

      </React.Fragment>
    )
  }
}

QuotePageConfirmation.pageLabel = 'Confirmation de votre demande de souscription'
QuotePageConfirmation.pagePath = 'confirmation'

function mapStateToProps (state) {
  return {
    quoteContext: state.quote
  }
}

export default connect(mapStateToProps, null)(
  withStyles(styles)(QuotePageConfirmation)
)
