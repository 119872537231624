import React from 'react'
import { Link } from 'gatsby'

import { withStyles, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import classNames from 'classnames'

import { siteTheme } from '../theme'
import LogoPhileass from '../../../images/logo_phileass_03.svg'
import LogoPhileassTop from '../../../images/logo_phileass_04.svg'

import MegaMenuMetiers from '../MegaMenuMetiers'
import MegaMenuAssurances from '../MegaMenuAssurances'
import HamburgerMenu from '../../ui/HamburgerMenu'
import HamburgerMenuAssurances from '../HamburgerMenuAssurances'
import HamburgerMenuMetiers from '../HamburgerMenuMetiers'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  appBar: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    height: '90px',
    paddingTop: '15px',
    paddingBottom: '15px',
    background: siteTheme.gradient1,
    boxShadow: siteTheme.boxShadowNone,
    transition: 'all 0.5s ease',
    '&.scroll': {
      boxShadow: siteTheme.boxShadow4,
      background: siteTheme.white
    }
  },
  toolbar: {
    padding: '0px'
  },
  // spaceGrow: {
  //   flexGrow: 1
  // },
  // spaceFix: {
  //   width: '0.8rem'
  // },
  appBarLogo: {
    width: '132px',
    height: 'auto'
    // height: '30px'
  },
  appBarTitleContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up(560)]: {
      marginRight: '132px'
    }
  },
  appBarTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    // textTransform: 'none',
    color: siteTheme.white,
    // boxShadow: siteTheme.boxShadowNone,
    backgroundColor: 'transparent',
    // borderRadius: 0,
    margin: theme.spacing.unit,
    '&.scroll': {
      color: siteTheme.color6
    }
  }
})

class HomeHeader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      scrolled: false
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll (e) {
    if (window.scrollY < 20) {
      this.setState({
        scrolled: false
      })
    } else {
      this.setState({
        scrolled: true
      })
    }
  }

  render () {
    const {
      classes,
      title,
      activeSection
    } = this.props
    const scrolled = this.state.scrolled

    return (
      <React.Fragment>
        <AppBar id='appbar'
          className={classNames(classes.appBar, { scroll: scrolled })}
        >
          <div className={classes.layout}>
            <Toolbar className={classes.toolbar}>
              <Link to='/'>
                <img
                  src={scrolled ? LogoPhileass : LogoPhileassTop}
                  alt='Phileass'
                  className={classes.appBarLogo}
                />
              </Link>

              <div className={classes.appBarTitleContainer}>
                <Typography
                  className={classNames(classes.appBarTitle, { scroll: scrolled })}
                >
                  { title }
                </Typography>
              </div>

            </Toolbar>
          </div>
        </AppBar>
      </React.Fragment>
    )
  }
}

HomeHeader.defaultProps = {
  title: 'Demande de devis'
}

export default withStyles(styles)(HomeHeader)
