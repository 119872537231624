import React from 'react'

import { Link } from 'gatsby'

import _ from 'lodash'
import classnames from 'classnames'
import ReactMarkdown from 'markdown-to-jsx'

import { FormattedNumber, FormattedPlural } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IcSelected from '@material-ui/icons/CheckCircle'
import IcCheck from '@material-ui/icons/Check'
import IcAdd from '@material-ui/icons/Add'
import IcAttachment from '@material-ui/icons/Attachment'
import IcInfo from '@material-ui/icons/InfoOutlined'

const styles = theme => ({
  root: {
    '&$selected': {
      // border: `solid 1px ${siteTheme.color3}`
    }
  },
  selected: {
    //
  },
  icSelectedContainer: {
    position: 'relative'
  },
  icSelected: {
    fontSize: '50px',
    position: 'absolute',
    top: '-12px',
    left: '-12px'
    // backgroundColor: siteTheme.white
  },

  topContainer: {
  },
  insurerLogoContainer: {
    position: 'relative',
    width: '100%',
    height: '70px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  insurerLogo: {
    display: 'block',
    width: '80%',
    maxWidth: '200px',
    maxHeight: '60px',
    margin: 'auto'
  },
  label: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    textAlign: 'center'
  },
  price: {
    textAlign: 'center',
    margin: `0px 0px ${theme.spacing.unit * 2}px 0px`
  },
  priceAmount: {
    fontSize: '3rem',
    fontWeight: 500,
    lineHeight: '3.5rem'
  },
  featuresContainer: {
    margin: `${theme.spacing.unit * 3}px 0px`
  },
  featuresLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  featureLabelContainer: {
    flex: '0 0 auto'
  },
  featureLabel: {
    // fontSize: '1.2rem',
    // fontWeight: 500,
    // lineHeight: '1.2rem',
    // textAlign: 'center'
  },
  featureSep: {
    flex: '1 1 auto'
  },
  featureValueContainer: {
    flex: '0 0 auto'
  },
  featureValue: {
    // fontSize: '1.2rem',
    fontWeight: 600
    // lineHeight: '1.2rem',
    // textAlign: 'center'
  },
  selectButton: {
    // margin: `${theme.spacing.unit * 3}px 0px`,
    padding: `${theme.spacing.unit}px`,
    textTransform: 'none',
    // boxShadow: siteTheme.boxShadow2
    // [theme.breakpoints.down('xs')]: {
    width: '100%'
    // }
  },
  coversList: {
    padding: '16px 0px'
  },
  coversListItem: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },

  expandActionArea: {
  },
  expandActionAreaContent: {
    display: 'flex'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },

  mdH1: {
    // fontSize: '40px',
    // fontWeight: 'bold',
    // lineHeight: '44px',
    // color: siteTheme.color3
    padding: '24px 0px'
  },
  mdH2: {
    padding: '32px 0px 16px 0px',
    '&:first-child': {
      padding: '0px 0px 16px 0px'
    }
  },
  mdH3: {
    padding: '16px 0px'
  },
  mdH4: {
    padding: '16px 0px'
  },
  mdP: {
    margin: `${theme.spacing.unit}px 0px`,
    textAlign: 'justify'
  },
  mdUl: {
    // listStyle: 'none',
    margin: `${theme.spacing.unit}px 0px`,
    padding: 0
  },
  mdLi: {
    // paddingLeft: `${theme.spacing.unit * 2}px`,
    marginLeft: '1.5em',
    marginBottom: theme.spacing.unit,
    textAlign: 'justify'
    // '&::before': {
    //   content: '"-"',
    //   display: 'inline-block',
    //   width: '1.5em',
    //   marginLeft: '-1.5em'
    //   // paddingRight: `${theme.spacing.unit * 2}px`
    // }
  }

})

const mdOptions = {
  // forceBlock: true,
  overrides: {
    h1: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='h2' className={classes.mdH1} id={id}>
          { children }
        </Typography>
      ))
    },
    h2: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='h3' className={classes.mdH2} id={id}>
          { children }
        </Typography>
      ))
    },
    h3: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='subtitle1' className={classes.mdH3} id={id}>
          { children }
        </Typography>
      ))
    },
    h4: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='caption' className={classes.mdH4} id={id}>
          { children }
        </Typography>
      ))
    },
    p: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        // <Typography variant='body2' className={classes.mdP}>
        //   { children }
        // </Typography>
        <>
          <ListItemIcon>
            <IcInfo color='primary' />
          </ListItemIcon>
          <ListItemText>
            { children }
          </ListItemText>
        </>
      ))
    },
    // Generate span for paragraphs ??
    span: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        <Typography inline variant='body2' className={classes.mdP}>
          { children }
        </Typography>
      ))
    },
    ul: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        // <ul className={classes.mdUl}>
        //   { children }
        // </ul>
        <List dense className={classes.coversList}>
          { children }
        </List>
      ))
    },
    li: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        // <li className={classes.mdLi}>
        //   {/* <Typography variant='body2' inline children={children} /> */}
        //   {children}
        // </li>
        <ListItem
          dense
          alignItems='flex-start'
          className={classes.coversListItem}
        >
          { children }
        </ListItem>
      ))
    }
  }
}

class ProductOfferCard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: false
    }

    this.handleExpandClick = () => {
      this.setState(state => ({ expanded: !state.expanded }))
    }
  }

  render () {
    const {
      productOffer,
      input: {
        // name,
        value,
        // onBlur,
        onChange
        // onFocus
      },
      classes
    } = this.props
    const {
      expanded
    } = this.state

    const productId = productOffer.insuranceProduct.id
    const productSelected = (value === productId)

    return (
      <React.Fragment>
        {
          productSelected &&
          <div className={classes.icSelectedContainer}>
            <IcSelected className={classes.icSelected} color='primary' />
          </div>
        }
        <Card
          className={classnames(classes.root, { [classes.selected]: productSelected })}
        >
          <CardContent className={classes.topContainer}>
            <div className={classes.insurerLogoContainer}>
              <img
                alt={productOffer.insuranceProduct.insurer.label}
                src={productOffer.insuranceProduct.insurer.imgLogo.url}
                className={classes.insurerLogo}
              />
            </div>
            <Typography variant='body2'
              className={classes.label}
            >
              {productOffer.insuranceProduct.label}
            </Typography>
            <div className={classes.price}>
              <Typography variant='body2'
                className={classes.priceAmount}
              >
                <FormattedNumber
                  style='currency'
                  currencyDisplay='symbol'
                  currency='EUR'
                  value={productOffer.price}
                />
                {/* {productOffer.price}&nbsp;€ */}
                <sup>*</sup>
              </Typography>
              <Typography variant='body2'>
                /an (TTC)
              </Typography>
            </div>
            <div className={classes.featuresContainer}>
              <div className={classes.featuresLineContainer}>
                <div className={classes.featureLabelContainer}>
                  <Typography variant='body2' className={classes.featureLabel}>
                    Plafond de couverture
                  </Typography>
                </div>
                <div className={classes.featureSep} />
                <div className={classes.featureValueContainer}>
                  <Typography variant='body2' className={classes.featureValue}>
                    <FormattedNumber
                      style='currency'
                      currencyDisplay='symbol'
                      currency='EUR'
                      value={productOffer.benefitLimit}
                    />
                    {/* {productOffer.benefitLimit} € */}
                  </Typography>
                </div>
              </div>
              {
                productOffer.deductibleAmount &&
                  <div className={classes.featuresLineContainer}>
                    <div className={classes.featureLabelContainer}>
                      <Typography variant='body2' className={classes.featureLabel}>
                        Franchise
                      </Typography>
                    </div>
                    <div className={classes.featureSep} />
                    <div className={classes.featureValueContainer}>
                      <Typography variant='body2' className={classes.featureValue}>
                        {
                          productOffer.deductibleAmount.value === 0
                            ? 'Aucune'
                            : (
                              <FormattedNumber
                                style='currency'
                                currencyDisplay='symbol'
                                currency='EUR'
                                value={productOffer.deductibleAmount.value}
                              />
                            )
                        }
                        {/* {productOffer.deductibleAmount.value} € */}
                      </Typography>
                    </div>
                  </div>
              }
            </div>
            <Button
              type='button'
              // variant={
              //   value === productId
              //     ? 'outlined'
              //     : 'contained'
              // }
              variant='outlined'
              color='primary'
              className={classes.selectButton}
              onClick={() => onChange(productSelected ? '' : productId)}
            >
              {
                productSelected
                  ? 'Retirer'
                  : 'Choisir'
              }
            </Button>
          </CardContent>
          <CardActionArea
            onClick={this.handleExpandClick}
            aria-expanded={expanded}
            aria-label='Plus de détails'
            className={classes.expandActionArea}
          >
            <CardContent className={classes.expandActionAreaContent}>
              <Typography variant='subtitle2'>
              Plus de détails
              </Typography>
              <ExpandMoreIcon
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
              />
            </CardContent>
          </CardActionArea>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              {
                (
                  !_.isEmpty(productOffer.insuranceProduct.insuranceCovers) ||
                  !_.isEmpty(productOffer.insuranceProduct.insuranceAdditionalCovers)
                ) &&
                <>
                  <Typography variant='subtitle1'>
                    Couvertures incluses
                  </Typography>
                  <List dense className={classes.coversList}>
                    {
                      productOffer.insuranceProduct.insuranceCovers.map(cover => (
                        <ListItem
                          dense
                          alignItems='flex-start'
                          className={classes.coversListItem}
                        >
                          <ListItemIcon>
                            <IcCheck color='primary' />
                          </ListItemIcon>
                          <ListItemText>
                            {cover.label}
                          </ListItemText>
                        </ListItem>
                      ))
                    }
                    {
                      productOffer.insuranceProduct.insuranceAdditionalCovers.map(cover => (
                        <ListItem
                          dense
                          alignItems='flex-start'
                          className={classes.coversListItem}
                        >
                          <ListItemIcon>
                            <IcCheck color='primary' />
                          </ListItemIcon>
                          <ListItemText>
                            {cover.label}
                          </ListItemText>
                        </ListItem>
                      ))
                    }
                  </List>
                </>
              }
              {
                !_.isEmpty(productOffer.insuranceProduct.productServices) &&
                  <>
                    <Typography variant='subtitle1'>
                      Services additionnels
                    </Typography>
                    <List dense className={classes.coversList}>
                      {
                        productOffer.insuranceProduct.productServices.map(cover => (
                          <ListItem
                            dense
                            alignItems='flex-start'
                            className={classes.coversListItem}
                          >
                            <ListItemIcon>
                              <IcAdd color='primary' />
                            </ListItemIcon>
                            <ListItemText>
                              {cover.label}
                            </ListItemText>
                          </ListItem>
                        ))
                      }
                    </List>
                  </>
              }
              {
                !_.isEmpty(productOffer.insuranceProduct.description) &&
                <>
                  <Typography variant='subtitle1'>
                    Description
                  </Typography>
                  <ReactMarkdown options={mdOptions}>
                    {productOffer.insuranceProduct.description}
                  </ReactMarkdown>
                </>
              }
              {
                (
                  !_.isEmpty(productOffer.insuranceProduct.docIPID) ||
                  !_.isEmpty(productOffer.insuranceProduct.docTC) ||
                  !_.isEmpty(productOffer.insuranceProduct.docEligibility)
                ) &&
                  <>
                    <Typography variant='subtitle1'>
                      Documents
                    </Typography>
                    <List dense className={classes.coversList}>
                      {
                        !_.isEmpty(productOffer.insuranceProduct.docIPID) &&
                          <>
                            <ListItem dense className={classes.coversListItem}
                              button
                              component='a' href={productOffer.insuranceProduct.docIPID.url} target='_blank'
                              alignItems='flex-start'
                            >
                              <ListItemIcon>
                                <IcAttachment color='primary' />
                              </ListItemIcon>
                              <ListItemText>
                                IPID
                              </ListItemText>
                            </ListItem>
                        </>
                      }
                      {
                        !_.isEmpty(productOffer.insuranceProduct.docTC) &&
                          <>
                            <ListItem dense className={classes.coversListItem}
                              button
                              component='a' href={productOffer.insuranceProduct.docTC.url} target='_blank'
                              alignItems='flex-start'
                            >
                              <ListItemIcon>
                                <IcAttachment color='primary' />
                              </ListItemIcon>
                              <ListItemText>
                                Conditions Générales
                              </ListItemText>
                            </ListItem>
                        </>
                      }
                      {
                        !_.isEmpty(productOffer.insuranceProduct.docEligibility) &&
                          <>
                            <ListItem dense className={classes.coversListItem}
                              button
                              component='a' href={productOffer.insuranceProduct.docEligibility.url} target='_blank'
                              alignItems='flex-start'
                            >
                              <ListItemIcon>
                                <IcAttachment color='primary' />
                              </ListItemIcon>
                              <ListItemText>
                                Conditions d'Eligibilité
                              </ListItemText>
                            </ListItem>
                        </>
                      }
                    </List>
                  </>
              }
              {
                !_.isEmpty(productOffer.insuranceProduct.insurer.description) &&
                  <>
                    <Typography variant='subtitle1'>
                      Assureur
                    </Typography>
                    <ReactMarkdown options={mdOptions}>
                      {productOffer.insuranceProduct.insurer.description}
                    </ReactMarkdown>
                  </>
              }
            </CardContent>
          </Collapse>
        </Card>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ProductOfferCard)
