import React from 'react'

import { Router } from '@reach/router'

import { withStyles } from '@material-ui/core/styles'

import QuotePageActivity from './QuotePageActivity'
import QuotePageCovers from './QuotePageCovers'
import QuotePageProducts from './QuotePageProducts'
import QuotePageSubscription from './QuotePageSubscription'
import QuotePageConfirmation from './QuotePageConfirmation'

import styles from './styles'

/**
 * Quote form component.
 *
 * @class QuoteForm
 * @extends {React.Component}
 */
class QuoteForm extends React.Component {
  render () {
    const {
      classes,
      // apiUrl,
      // gReCPATCHAKey,
      basePath,
      ...customProps
    } = this.props

    return (
      <React.Fragment>

        <Router basepath={basePath}>

          {/* <Redirect from='/' to='votre-societe' /> */}

          <QuotePageActivity
            path='votre-societe'
            default
            classes={classes}
            wizard={{
              pageIndex: 0,
              // previousPath: '',
              nextPath: `${basePath}/vos-couvertures`
            }}
            {...customProps}
          />

          <QuotePageCovers
            path='vos-couvertures'
            classes={classes}
            wizard={{
              pageIndex: 1,
              previousPath: `${basePath}/votre-societe`,
              nextPath: `${basePath}/vos-devis`
            }}
            {...customProps}
          />

          <QuotePageProducts
            path='vos-devis'
            classes={classes}
            // formData={formData}
            wizard={{
              pageIndex: 2,
              previousPath: `${basePath}/vos-couvertures`,
              nextPath: `${basePath}/souscription`
            }}
            {...customProps}
          />

          <QuotePageSubscription
            path='souscription'
            classes={classes}
            // formData={formData}
            wizard={{
              pageIndex: 3,
              previousPath: `${basePath}/vos-devis`,
              nextPath: `${basePath}/confirmation`
            }}
            {...customProps}
          />

          <QuotePageConfirmation
            path='confirmation'
            classes={classes}
            // formData={formData}
            wizard={{
              pageIndex: 4
              // previousPath: `${basePath}/vos-devis`
              // nextPath: 'vos-devis'
            }}
            {...customProps}
          />

        </Router>

      </React.Fragment>
    )
  }
}

export default withStyles(styles)(QuoteForm)
