import React from 'react'

import { FormSection, Field } from 'redux-form'

import { withStyles } from '@material-ui/core'

import FTextField from './FTextField'

const styles = (theme) => ({
  textField: {
    margin: `${theme.spacing.unit * 1.5}px 0px`
  }
})

class FAddressFields extends React.Component {
  render () {
    const {
      sectionName,
      fieldBaseId,
      classes
    } = this.props

    return (
      <FormSection name={sectionName}>
        <Field component={FTextField}
          id={`${fieldBaseId}_addressLine1`}
          name='addressLine1'
          autoComplete='address-line1'
          label='Adresse'
          placeholder="Saisissez l'adresse occupée."
          required
          className={classes.textField}
        />
        <Field component={FTextField}
          id={`${fieldBaseId}_addressLine2`}
          name='addressLine2'
          autoComplete='address-line2'
          label='Adresse (suite)'
          // placeholder="Saisissez l'adresse de votre emplacement"
          // required
          className={classes.textField}
        />
        <Field component={FTextField}
          id={`${fieldBaseId}_postalCode`}
          name='postalCode'
          autoComplete='postal-code'
          label='Code Postal'
          placeholder='Entrez votre code postal'
          required
          className={classes.textField}
        />
        <Field component={FTextField}
          id={`${fieldBaseId}_city`}
          name='city'
          autoComplete='address-level2'
          label='Ville'
          placeholder='Entrez le nom de la ville'
          required
          className={classes.textField}
        />
        {/* <Field component={FTextField}
          id={`${fieldBaseId}_country`}
          name='country'
          // autoComplete='country-name'
          autoComplete='country'
          label='Pays'
          placeholder='Entrez le pays'
          required
          className={classes.textField}
        /> */}
      </FormSection>
    )
  }
}

export default withStyles(styles)(FAddressFields)
