import React from 'react'

import _ from 'lodash'

import * as API from '../../../api/API'

import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import QuotePageActivityForm from './QuotePageActivityForm'

class QuotePageActivity extends React.Component {
  constructor (props) {
    super(props)

    // State definition
    this.state = {
      isFormInitialized: false,
      isLoading: false,
      formData: {
        businessActivities: {
          isLoading: false,
          isInitialized: false,
          isSuccess: false,
          error: false,
          data: []
        },
        legalForms: {
          isLoading: false,
          isInitialized: false,
          isSuccess: false,
          error: '',
          data: []
        }
      },
      initialValues: QuotePageActivityForm.initialValues
    }

    // Bind class methods
    this.loadBusinessActivities = this.loadBusinessActivities.bind(this)
    this.loadLegalForms = this.loadLegalForms.bind(this)
    this.loadFormData = this.loadFormData.bind(this)
  }

  loadBusinessActivities () {
    if (!this.state.formData.businessActivities.isInitialized) {
      // Loading indicator
      this.setState(state => ({
        formData: _.merge({}, state.formData, {
          businessActivities: {
            isLoading: true
          }
        })
      }))
      // Make API call
      API.getBusinessCategories(this.props.apiUrl).then(
        (businessCategories) => {
          // TODO: filter and group options
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              businessActivities: {
                isLoading: false,
                isInitialized: true,
                isSuccess: true,
                error: '',
                // Group: array, label/options
                // data: businessActivities.map(activity => ({
                //   value: activity.id,
                //   label: activity.label
                // }))
                // data: [
                //   {
                //     label: 'Hello',
                //     options: businessActivities.map(activity => ({
                //       value: activity.id,
                //       label: activity.label
                //     }))
                //   }
                // ],
                data: businessCategories.map(category => ({
                  label: category.label,
                  options: category.businessActivities.map(activity => ({
                    value: activity.id,
                    label: activity.label
                  }))
                }))
              }
            })
          }))
        },
        (error) => {
          // TODO
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              businessActivities: {
                isLoading: false,
                isInitialized: false,
                isSuccess: false,
                error: error,
                data: []
              }
            })
          }))
        }
      ).then(
        () => {
          this.setState(state => ({
            isFormInitialized: state.formData.businessActivities.isInitialized && state.formData.legalForms.isInitialized,
            isLoading: state.formData.businessActivities.isLoading || state.formData.legalForms.isLoading
          }))
        }
      )
    }
  }

  loadLegalForms () {
    if (!this.state.formData.legalForms.isInitialized) {
      // Loading indicator
      this.setState(state => ({
        formData: _.merge({}, state.formData, {
          legalForms: {
            isLoading: true
          }
        })
      }))
      // Make API call
      API.getLegalFormCategories(this.props.apiUrl).then(
        (legalForms) => {
          // TODO: filter and group options
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              legalForms: {
                isLoading: false,
                isInitialized: true,
                isSuccess: true,
                error: '',
                data: legalForms
              }
            })
          }))
        },
        (error) => {
          this.setState(state => ({
            formData: _.merge({}, state.formData, {
              legalForms: {
                isLoading: false,
                isInitialized: false,
                isSuccess: false,
                error: error,
                data: []
              }
            })
          }))
        }
      ).then(
        () => {
          this.setState(state => ({
            isFormInitialized: state.formData.businessActivities.isInitialized && state.formData.legalForms.isInitialized,
            isLoading: state.formData.businessActivities.isLoading || state.formData.legalForms.isLoading
          }))
        }
      )
    }
  }

  loadFormData () {
    if (this.props.apiUrl === undefined) {
      // Error
      return
    }
    if (this.state.isLoading) {
      // Already loading data
      return
    }
    this.setState({
      isLoading: true
    })

    this.loadBusinessActivities()
    this.loadLegalForms()
  }

  componentDidMount () {
    this.loadFormData()
  }

  render () {
    const {
      // Router
      navigate,
      // Wizard
      wizard,
      ...customProps
    } = this.props

    const {
      isLoading,
      formData,
      initialValues
    } = this.state

    return (
      <QuotePageActivityForm
        navigate={navigate}
        wizard={wizard}
        formData={formData}
        initialValues={initialValues}
        isLoading={isLoading}
        {...customProps}
      />
    )
  }
}

QuotePageActivity.pageLabel = 'Votre société et vous'
QuotePageActivity.pagePath = 'votre-societe'

export default withStyles(styles)(QuotePageActivity)
