import React from 'react'

import _ from 'lodash'

import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import QuotePageSubscriptionForm from './QuotePageSubscriptionForm'

class QuotePageSubscription extends React.Component {
  constructor (props) {
    super(props)

    // State definition
    this.state = {
      // isFormInitialized: false,
      isFormInitialized: true,
      isLoading: false,
      initialValues: QuotePageSubscriptionForm.initialValues
    }
  }

  componentDidMount () {
    this.setState(state => ({
      initialValues: _.merge({}, state.initialValues,
        QuotePageSubscriptionForm.computeInitialValues(this.props.activityValues)
      )
    }))
  }

  render () {
    const {
      // Router
      navigate,
      // Wizard
      wizard,
      ...customProps
    } = this.props

    const {
      isLoading,
      initialValues
    } = this.state

    return (
      <QuotePageSubscriptionForm
        navigate={navigate}
        wizard={wizard}
        initialValues={initialValues}
        isLoading={isLoading}
        {...customProps}
      />
    )
  }
}

QuotePageSubscription.pageLabel = 'Identification de votre entreprise'
QuotePageSubscription.pagePath = 'souscription'

// Selector decorator
const formSelector = formValueSelector('quoteActivity')

function mapStateToProps (state) {
  return {
    activityValues: {
      companyName: formSelector(state, 'companyName')
    }
  }
}

export default connect(mapStateToProps, null)(
  withStyles(styles)(QuotePageSubscription)
)
